<template>
    <el-menu
        :default-active="routerPath"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        @select="handleSelect"
        background-color="#000"
        text-color="#fff"
        active-text-color="#fff"
      >
        <el-submenu index="customer" v-if="adminType === '2'" >
          <template slot="title">
            <i class="el-icon-user"></i>
            <span>客户管理</span>
          </template>
          <el-menu-item index="/back/customerC">C端客户管理</el-menu-item>
          <el-menu-item index="/back/statistics">客户统计</el-menu-item>
          <el-menu-item index="/back/allocation">服务分配</el-menu-item>
          <el-menu-item index="/back/userManagement">用户管理</el-menu-item>
          <el-menu-item index="/back/discountCodeManagement">优惠码管理</el-menu-item>
        </el-submenu>

        <el-submenu index="order" v-if="adminType === '2'" >
          <template slot="title">
            <i class="el-icon-money"></i>
            <span>订单管理</span>
          </template>
        </el-submenu>

        <el-submenu index="information" v-if="adminType === '2'">
          <template slot="title">
            <i class="el-icon-collection"></i>
            <span>信息库</span>
          </template>
          <el-menu-item index="/back/schoolSearch">院校信息查询</el-menu-item>
          <el-menu-item index="/back/majorSearch">专业信息查询</el-menu-item>
          <el-menu-item index="/back/scoreSearch">分数查询</el-menu-item>
          <el-menu-item index="/back/selectSubject">新高考选科</el-menu-item>
          <el-menu-item index="/back/batchSearch">批次线查询</el-menu-item>
          <el-menu-item index="/back/schoolHKMSearch">港澳院校查询</el-menu-item>
          <el-menu-item index="/back/schoolTWSearch">台湾院校查询</el-menu-item>
        </el-submenu>

        <el-submenu index="4" v-if="adminType === '2'">
          <template slot="title">
            <i class="el-icon-reading"></i>
            <span>教育管理</span>
          </template>
        </el-submenu>

        <el-submenu index="5" v-if="adminType === '2'">
          <template slot="title">
            <i class="el-icon-office-building"></i>
            <span>高考报考</span>
          </template>
        </el-submenu>

        <el-submenu index="application">
          <template slot="title">
            <i class="el-icon-notebook-2"></i>
            <span>志愿填报</span>
          </template>
          <el-menu-item index="/back/createArchive">创建档案</el-menu-item>
          <el-menu-item index="/back/archiveList">学生档案表</el-menu-item>
        </el-submenu>
      </el-menu>
</template>

<script>
export default {
    name: "backSidebar",
    props: {
        msg: String,
    },
    components: {
    },
    computed:{
      routerPath(){
        return this.$router.meta && this.$router.meta.guidePath? this.$router.meta.jumpPath: this.$router.path;
      }
    },
    data() {
        return {
          adminType:'1'
        };
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        handleSelect(index){
          this.$router.push({path:index})
        },
    },
    mounted(){
      this.adminType = this.$store.getters.getAdminInfo.adminType.toString();
    }
}
</script>
<style lang="less" scoped>
  .el-menu {
    height: calc(100% - 80px);
    overflow-y: scroll;
  }
  .el-menu::-webkit-scrollbar { 
    width: 0 !important;
  }
  .el-submenu .el-menu-item {
    min-width: 0px;
  }

  .el-menu-item {
    border-top: 1px solid rgba(0, 0, 0, 0.5) !important;
    background-color: #646464 !important;
    opacity: 0.65;
  }
  .el-menu-item:hover {
    background-color: #646464 !important;
  }
  .el-menu-item.is-active {
    background-color: #ff450b !important;
    opacity: 1;
  }
  .el-submenu__title {
    background-color: #000 !important;
  }
  .el-submenu__title:hover {
    background-color: #000 !important;
  }
</style>