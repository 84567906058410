<template>
  <div class="test com">
    <hd></hd>
    <div class="main-screen">
      <div class="left-bar">
        <div class="bar-box" v-for="(item, index) in leftBar" :key="index">
          <div class="box-label">
            <i class="if" :class="item.icon"></i>
            <span>
              {{ item.label }}
            </span>
          </div>
          <div
            class="bar-item"
            v-for="(item, index) in item.items"
            :key="index"
          >
            <span style="color: #ff450b">
              {{ item.label }}
            </span>
          </div>
        </div>
      </div>
      <div class="main-wrap">
        <div class="mian-title">
          <i class="if iling"></i>
          <span> 职业性格测试 </span>
        </div>
        <div class="main-box">
          <div class="start-box" v-if="!started">
            <div class="box-title">《职业性格测试》</div>
            <div class="btn-start" @click="startTest()">开始测试</div>
            <div class="start-msg">本测试一共99道题，请在30分钟内作答完毕</div>
          </div>
          <div class="test-box" v-else>
            <testFrom :formData="testData"></testFrom>
          </div>
        </div>
      </div>
    </div>
    <ft></ft>
  </div>
</template>

<script>
import hd from "../common/header/hd";
import ft from "../common/footer/ft";
import testFrom from "../common/form/testForm";

export default {
  name: "characterTest",
  components: {
    hd,
    ft,
    testFrom,
  },
  props: {},
  data() {
    return {
      started: false,
      leftBar: [
        {
          label: "首页",
          icon: "iling",
          url: "",
          items: [],
        },
        {
          label: "测评",
          icon: "iling",
          url: "",
          items: [
            {
              label: "职业性格测试",
              url: "",
            },
            // {
            //   label: "职业性格测试1",
            //   url: "",
            // },
            // {
            //   label: "职业性格测试2",
            //   url: "",
            // },
          ],
        },
        // {
        //   label: "职业规划",
        //   icon: "iling",
        //   url: "",
        //   items: [{
        //     label: "职业性格测试",
        //     url: "",
        //   },
        //   {
        //     label: "职业性格测试1",
        //     url: "",
        //   },
        //   {
        //     label: "职业性格测试2",
        //     url: "",
        //   },
        //   ],
        // },
      ],
      testData: [],
    };
  },
  mounted() {
    this.pageInit();
  },
  methods: {
    async pageInit() {
      let this_ = this;
      await this.$http
        .get(this.$apis.getAnalysisReport, {
          userId: JSON.parse(localStorage.getItem("userInfo")).id,
        })
        .then((res) => {
          if (res.message != "当前用户未答题，请先答题!") {
            this_.$router.push({ path: "/character/report" });
          }
        });

      this.$http
        .post(this.$apis.getQuestionList, { page: 0, size: 0 })
        .then((res) => {
          this_.testData = res.data.list;
        });
    },
    startTest() {
      this.started = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.main-screen {
  min-height: calc(100vh - 480px);
  padding: 50px 0 100px;
  display: flex;
}

.left-bar {
  width: 250px;
  padding: 0 50px;

  .bar-box {
    padding: 20px 0 20px;
    position: relative;

    ::after {
      content: "";
      position: absolute;
      display: inline-block;
      width: 175px;
      height: 1px;
      background-color: #bbb;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .bar-box:last-child {
    ::after {
      display: none;
    }
  }

  .box-label {
    font-size: 20px;
    color: #646464;
    display: flex;
    align-items: center;

    i {
      color: #ff450b;
      font-size: 20px;
      padding-right: 15px;
    }
  }

  .bar-item {
    color: #101010;
    font-size: 16px;
    padding: 6px 0 6px 35px;
  }
}

.main-wrap {
  width: 950px;
  padding-left: 50px;

  .mian-title {
    color: #939393;
    font-size: 14px;
    padding: 20px;
  }

  .main-box {
    box-shadow: 2px 2px 13px 1px rgba(0, 0, 0, 0.12);
    width: 100%;
  }

  .start-box {
    width: 100%;
    height: 350px;
    padding-top: 100px;
    .box-title {
      color: #ff450b;
      font-size: 30px;
      text-align: center;
      margin-bottom: 30px;
    }

    .btn-start {
      color: #ff450b;
      background-color: #ffa005;
      line-height: 45px;
      text-align: center;
      margin: 0 auto;
      border-radius: 5px;
      width: 120px;
      margin-bottom: 20px;
    }

    .start-msg {
      color: #b0b0b0;
      font-size: 14px;
      text-align: center;
    }
  }

  .test-box {
  }
}
</style>