import VueRouter from "vue-router";
// import helloworld from "../components/helloworld/HelloWorld";
import home from "../components/home/home";
import userCenter from "../components/userCenter/userCenter";
import colleges from "../components/colleges/colleges";
import planner from "../components/planner/planner";
import characterTest from "../components/character/test";
import characterReport from "../components/character/report";
// auth
import authLogin from "../components/auth/authLogin";
import adminAuth from "../components/auth/adminAuth";
import authAdd from "../components/auth/authAdd";
// back
import back from "../components/back/home";
import backLogin from "../components/back/backLogin";
import backRegister from "../components/back/backRegister";

import { Decrypt } from "../utils/secret";

import Vue from 'vue';
import VueCookies from 'vue-cookies';
Vue.use(VueRouter); //挂载属性
const routes = [{
    path: '/',
    component: backLogin
  },
  {
    path: '/home',
    component: home
  },
  {
    path: '/back/login',
    component: backLogin
  },
  {
    path: '/userCenter',
    component: userCenter
  },
  {
    path: '/colleges',
    component: colleges
  },
  {
    path: '/planner',
    component: planner
  },
  {
    path: '/character/test',
    component: characterTest
  },
  {
    path: '/character/report',
    component: characterReport
  },
  // auth
  {
    path: '/auth/login',
    component: authLogin
  },
  {
    path: '/auth/adminAuth',
    component: adminAuth
  },

  {
    path: '/auth/authAdd',
    component: authAdd
  },

  // back
  {
    path:'/back',
    component:back,
    meta:{ 
      permission:["0","1","2"] 
    },
    children:[
      {
        path:'/back/index',
        component:()=>import('../components/back/index'),
        meta:{ 
          permission:["0","1","2"] 
        }
      },
      {
        path:'/back/userCenter',
        component:()=>import('../components/back/userCenter'),
        meta:{ 
          permission:["0","1","2"]
        }
      },
      {
        path:'/back/customerC',
        component:()=>import('../components/back/customerManagement/customerC'),
        meta:{ 
          permission:["2"]
        }
      },
      {
        path:'/back/userManagement',
        component:()=>import('../components/back/customerManagement/userManagement'),
        meta:{ 
          permission:["2"]
        }
      },
      {
        path:'/back/discountCodeManagement',
        component:()=>import('../components/back/customerManagement/discountCodeManagement'),
        meta:{ 
          permission:["2"]
        }
      },
      {
        path:'/back/statistics',
        component:()=>import('../components/back/customerManagement/statistics'),
        meta:{ 
          permission:["2"]
        }
      },
      {
        path:'/back/allocation',
        component:()=>import('../components/back/customerManagement/allocation'),
        meta:{ 
          permission:["2"]
        }
      },
      {
        path:'/back/schoolSearch',
        component:()=>import('../components/back/informationBase/school'),
        meta:{ 
          permission:["2"]
        }
      },
      {
        path:'/back/majorSearch',
        component:()=>import('../components/back/informationBase/major'),
        meta:{ 
          permission:["2"]
        }
      },
      {
        path:'/back/scoreSearch',
        component:()=>import('../components/back/informationBase/score'),
        meta:{ 
          permission:["2"]
        }
      },
      {
        path:'/back/selectSubject',
        component:()=>import('../components/back/informationBase/subject'),
        meta:{ 
          permission:["2"]
        }
      },
      {
        path:'/back/batchSearch',
        component:()=>import('../components/back/informationBase/batch'),
        meta:{ 
          permission:["2"]
        }
      },
      {
        path:'/back/schoolHKMSearch',
        component:()=>import('../components/back/informationBase/schoolHKM'),
        meta:{ 
          permission:["2"]
        }
      },
      {
        path:'/back/schoolTWSearch',
        component:()=>import('../components/back/informationBase/schoolTW'),
        meta:{ 
          permission:["2"]
        }
      },

      {
        path:'/back/createArchive',
        component:()=>import('../components/back/collegeApplication/createArchive'),
        meta:{ 
          permission:["0","1","2"]
        }
      },
      {
        path:'/back/archiveList',
        component:()=>import('../components/back/collegeApplication/archiveList'),
        meta:{ 
          permission:["0","1","2"]
        }
      },
      {
        path:'/back/myApplication',
        name:'myApplication',
        component:()=>import('../components/back/collegeApplication/myApplication'),
        meta:{
          guidePath:true,
          permission:["0","1","2"],
          jumpPath:"/back/archiveList"
        }
      },
      {
        path:'/back/applicationInfo',
        name:'applicationInfo',
        component:()=>import('../components/back/collegeApplication/applicationInfo'),
        meta:{
          guidePath:true,
          permission:["0","1","2"],
          jumpPath:"/back/archiveList"
        }
      },
    ],
    beforeEnter:(to,from,next)=>{
      var adminInfoEnc = localStorage.getItem("adminInfo");
      var adminInfo = null;
      if(adminInfoEnc){
        adminInfo = JSON.parse(Decrypt(adminInfoEnc));
      }
      var token = localStorage.getItem('ACCESS_TOKEN');
      var cookieToken = VueCookies.get('ACCESS_TOKEN');
      if(adminInfo && adminInfo.id && token && cookieToken){
        if(to.meta && to.meta.permission && to.meta.permission.indexOf(adminInfo.adminType.toString()) > -1){
          next();
        }else{
          Vue.prototype.$message.error("无访问权限!");
          next('/back');
        }
      }
      else if(!cookieToken || cookieToken!=token){
        Vue.prototype.$message.error("身份认证已过期, 请重新登录!");
        localStorage.removeItem('ACCESS_TOKEN');
        VueCookies.remove('ACCESS_TOKEN');
        next('/back/backLogin');
      }
      else{
        Vue.prototype.$message.error("请先登录再进行操作!");
        localStorage.removeItem('ACCESS_TOKEN');
        VueCookies.remove('ACCESS_TOKEN');
        next('/back/backLogin');
      }
    }
  },
  {
    path: '/back/backLogin',
    component: backLogin
  },
  {
    path: '/back/backRegister',
    component: backRegister
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})
export default router;