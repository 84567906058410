<template>
  <div class="main-shade com" @click="disShade()">
    <div class="signup-wrap" @click.stop="">
      <div class="lw-left">
        <div class="lwl-title">AI填报系统</div>
        <div class="lwl-msg">
          高考报考，<br />
          就选AI智能填报
        </div>
      </div>
      <div class="lw-right">
        <!-- <div class="log-switch">
          <div
            class="sw-signup"
            :class="{ 'log-sw-checked': !isLog }"
            @click="logSwitch(false)"
          >
            登录
          </div>
          <div
            class="sw-login"
            :class="{ 'log-sw-checked': isLog }"
            @click="logSwitch(true)"
          >
            注册
          </div>
        </div> -->
        <div class="log-title">手机号码登录</div>
        <el-input class="bot-border" v-model="loginInfo.phone" placeholder="手机号" type="tel"></el-input>
        <div class="code-box">
          <el-input class="bot-border" v-model="loginInfo.authCode" placeholder="验证码"></el-input>
          <div v-if="codeTime == 60" class="btn-code" @click="getCode()">
            获取验证码
          </div>
          <div v-else class="btn-code-pre">{{ codeTime }}s后重试</div>
        </div>
        <el-input class="bot-border" v-model="loginInfo.fullname" placeholder="学生姓名"></el-input>
        <el-select class="bot-border" v-model="loginInfo.region" placeholder="学生所在地">
          <el-option v-for="item in locationList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

        <div class="btn-signup" v-if="!isLog" @click="userLogin()">登录</div>
        <div class="btn-login" v-if="isLog" @click="userSingup()">注册</div>
        <div class="pwd-wrap">
          <el-checkbox v-model="rePwd">记住密码</el-checkbox>
          <div>忘记密码？</div>
        </div>
        <div class="signup-other"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  props: {
    msg: String,
  },

  data() {
    return {
      isLog: false,
      rePwd: false,
      loginInfo: {
        phone: "",
        authCode: "",
        fullname: "",
        region: "",
        time: "",
        hash: "",
      },
      codeTime: 60,
      locationList: [],
    };
  },

  mounted() {
    this.locationList = this.$store.getters.getLocationList.map((item) => {
      return {
        label: item.name,
        value: item.name,
      };
    });
  },
  methods: {
    logSwitch(bo) {
      this.isLog = bo;
    },
    userSingup() {
      // this.isShade = false;
    },
    disShade() {
      this.$parent.loginPop = false;
    },
    userLogin() {
      let this_ = this;
      if (
        !(
          this.loginInfo.phone &&
          this.loginInfo.authCode &&
          this.loginInfo.region &&
          this.loginInfo.fullname
        )
      ) {
        this_.$message({
          message: "请填写正确信息！",
          type: "error",
        });
        return;
      }
      if (!(this.loginInfo.time && this.loginInfo.time)) {
        this_.$message({
          message: "验证码出错，请重新获取！",
          type: "warning",
        });
        return;
      }

      this.$http.post(this.$apis.login, this_.loginInfo).then((res) => {
        if (res.fullname) {
          this_.$store.commit("setUserInfo", res);
          localStorage.setItem("userInfo", JSON.stringify(res))
          this_.$parent.userInfo = this_.$store.getters.getUserInfo;
          this_.disShade();
        }
      });
    },

    getCode() {
      let this_ = this;
      if (this.loginInfo.phone.length !== 11) {
        this_.$message({
          message: "请输入正确手机号！",
          type: "warning",
        });
        return;
      }
      if (this.codeTime == 60) {
        this.$http
          .get(this.$apis.getAuthCode, { phone: this_.loginInfo.phone })
          .then((res) => {
            this_.loginInfo.time = res.time;
            this_.loginInfo.hash = res.hash;
          });
        this.codeTime--;
      }
      let clock = window.setInterval(() => {
        this.codeTime--;
        if (this.codeTime < 0) {
          window.clearInterval(clock);
          this.codeTime = 60;
        }
      }, 1000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.bot-border {
  margin-bottom: 20px;
  width: 100%;
}
.main-shade {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.signup-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 700px;
  height: 475px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  display: flex;
}

.lw-left {
  width: 300px;
  background: url("../../../assets/img/logBack.jpg");
  background-size: 100% 100%;
  color: #fff;
  padding: 90px 0 0 50px;
  font-size: 15px;
}
.lwl-title {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 30px;
}
.lw-right {
  width: 400px;
  padding: 0 40px;
}

.log-switch {
  display: flex;
  margin-bottom: 35px;
}
.sw-signup,
.sw-login {
  width: 50%;
  line-height: 40px;
  text-align: center;
  font-size: 15px;
  border-bottom: 1px solid rgb(211, 211, 211);
}

.log-sw-checked {
  color: #ff450b;
  border-color: #ff450b;
}

.input-username,
.input-pwd {
  margin-bottom: 25px;
}
.btn-signup,
.btn-login {
  line-height: 50px;
  background-color: #ff450b;
  color: #fff;
  font-size: 15px;
  text-align: center;
  border-radius: 25px;
  cursor: pointer;
}

.pwd-wrap {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 40px;
  div {
    font-size: 14px;
    color: #ff450b;
  }
}

.log-title {
  font-size: 18px;
  color: #333;
  text-align: center;
  position: relative;
  padding: 50px 0 5px;
  margin-bottom: 25px;
}

.log-title::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  width: 35px;
  background-color: #ff450b;
}

.code-box {
  position: relative;
  .btn-code,
  .btn-code-pre {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-90%);
    cursor: pointer;
    color: #ff450b;
    line-height: 30px;
    width: 100px;
    text-align: center;
    border: 1px solid #ff450b;
    border-radius: 15px;
    font-size: 12px;
  }

  .btn-code-pre {
    color: #ccc;
    border: 1px solid #ccc;
  }
}
</style>
