<template>
  <div class="report com">
    <hd></hd>
    <div class="main-screen">
      <div class="left-bar">
        <div class="bar-box" v-for="(item, index) in leftBar" :key="index">
          <div class="box-label">
            <i class="if" :class="item.icon"></i>
            <span>
              {{ item.label }}
            </span>
          </div>
          <div
            class="bar-item"
            v-for="(item, index) in item.items"
            :key="index"
          >
            <span style="color: #ff450b">
              {{ item.label }}
            </span>
          </div>
        </div>
      </div>
      <div class="main-wrap">
        <div class="mian-title">
          <i class="if iling"></i>
          <span>用户分析</span>
        </div>

        <div class="main-box title">测试结果</div>

        <div class="main-box" style="padding: 20px 0">
          <div class="sum-box">
            <div class="sun-label">
              代表职业
              <div
                v-for="(item, index) in professionList[
                  userReport[0].respList[0].type
                ]"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
            <div class="sun-msg">
              {{ subMsg[userReport[0].respList[0].subType] }}
            </div>
          </div>
        </div>

        <div class="main-box report">
          <div class="report-label">
            测评结果
            <div class="btn-export" @click="reportExport()">导出</div>
          </div>
          <div class="label">维度图</div>
          <div class="rb">
            <div class="rb-l">
              <div id="chart1" style="width: 300px; height: 300px"></div>
            </div>
            <div class="rb-r">
              <div
                class="rb-label"
                v-for="(item, index) in cMsg1.label"
                :key="index"
              >
                {{ item }}
                {{ charts[0].vals[index] }}分，
                {{ cMsg1.msg[index] }}
              </div>
            </div>
          </div>
        </div>

        <div class="main-box report">
          <div class="report-label">
            测评结果
            <div class="btn-export" @click="reportExport()">导出</div>
          </div>
          <div class="label">维度图</div>
          <div class="rb">
            <div class="rb-l">
              <div id="chart3" style="width: 400px; height: 400px"></div>
            </div>
            <div class="rb-r">
              <div
                class="rb-label"
                v-for="(item, index) in cMsg3.label"
                :key="index"
              >
                {{ item }}
                {{ charts[2].vals[index] || 0 }}分，
                {{ cMsg3.msg[index] }}
              </div>
            </div>
          </div>
        </div>

        <div class="main-box report2">
          <div class="report-label">
            测评结果
            <div class="btn-export" @click="reportExport()">导出</div>
          </div>
          <div class="label">柱状图</div>
          <div class="rb">
            <div class="rb-l">
              <div
                id="chart2"
                style="width: 600px; height: 300px; margin: 0 auto"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ft></ft>
  </div>
</template>
<script>
import hd from "../common/header/hd";
import ft from "../common/footer/ft";
import * as echarts from "echarts";

export default {
  name: "characterReport",
  components: {
    hd,
    ft,
  },
  props: {},
  data() {
    return {
      userReport: [
        {
          respList: [
            {
              subType: null,
            },
          ],
        },
      ],
      subType: "",
      professionList: {
        SJ: ["经理人员", "会计", "警察", "医生", "教师"],
        SP: ["表演者", "企业家", "排除故障者", "自由职业者"],
        NT: ["科学家", "建筑师", "工程师", "设计师", "经理人员"],
        NF: ["咨询师", "记者", "艺术家", "心理学家"],
      },
      subMsg: {
        ISTJ:
          "严肃，安静，通过全神贯注和细致周到而赢得成功。实际，有序，关注事实，有逻辑，现实，可信赖。注意把每件事情都组织得很好。做事负责。对该做的事情有主见；不顾反对和干扰，能逐步实施自己的想法。",
        ISFJ:
          "安静，友好，负责有责任感。工作投入，力图满足职责要求，热心为朋友和群体服务。周到、刻苦、准确。尽管其兴趣并不常在技术领域，但可能花时间去掌握技术方面的知识和技能。对细节和常规工作有耐心。忠诚，体贴，为他人着想，关心他人的感受。",
        ESTJ:
          "注重实际的现实主义者，关注事实；天生的商业和力学头脑；对他们认为没有用处的科目不感兴趣，但在必要时也能专注其中；喜欢组织并管理活动。如果能考虑到别人的情感、感受和观点的话，能够将事情管理得非常出色。",
        ESFJ:
          "热心，健谈，合群，认真尽责；是“天生的合作者”、活跃的“委员会成员”。总是善待他人。在受到充分的鼓励和赞扬时表现出色。对抽象的思考和技术性的课题缺乏兴趣。主要兴趣在于那些能直接的、可见地影响人们生活的事情。",
        ISTP:
          "冷静的旁观者,安静,寡言，以超然的好奇心、求知欲观察和分析生活，时有出人意料的独特的幽默言行。通常对客观规律、机械事物的工作原理、因果关系感兴趣。因为他们认为浪费精力是缺乏效率的，所以只有在他们认为必要的时候才会表达意见或发挥自己的影响",
        ISFP:
          "孤独缄默，友善，敏感，对自己能力保持谦逊的态度。避免表现自己的不同意见，不把自己的意见和价值观强加于人。通常不愿意做领导或挑头，但常常是忠诚的追随者。爱享受当前的时刻，并且不想过分勉力而为，因此对于明确分配的任务和工作顺利进行感到相当放松。",
        ESTP:
          "关注事实，不焦虑，不着急，随遇而安。喜欢机械的事物和体育运动，支持并偏向朋友的一方。可能有点儿直率和不敏感。在他们认为需要的时候，可以在数学和其他自然科学方面应付裕如。不喜欢冗长的解释。擅长动手操作、拆开并重组物体。",
        ESFP:
          "外向，随和，易接纳，友善，喜欢享乐。喜欢运动和制作东西。清楚当前发生的事情并渴望参与其中；相对于掌握理论而言，更容易发现易于记忆的事实。在需要丰富的常识和与人、事打交道的实际能力的场合中，表现出色。",
        INTJ:
          "具有独创性的思想和强烈的内驱力，通常只用以达到自己的目的。在对其有吸引力的领域，无论有无帮助，他们都具有一种良好的能力来组织工作并付诸实施。对事物持怀疑态度，好批评,独立,果断，时常固执己见。为了在最重要的方面上获胜，必须学会在一些不太重要的方面上作出让步。",
        INTP:
          "安静，缄默含蓄；在理论性或科学性的科目上表现尤为出色。注重逻辑，有时甚至到了吹毛求疵的地步。通常对观念和思想感兴趣，但不太喜欢聚会或闲谈。常常有十分明确限定的兴趣或爱好。需要选择从事那些自己的兴趣能够用得上且有用职业。",
        ENTJ:
          "为人诚恳,坦白,有学习能力,是活动中的领导者。通常在要求推理和机智的对话的活动中表现良好（例如在公开的演说或谈话中）。了解很多信息，能不断积累知识。有时在其受任的领域内，表现出比经验更多的把握和信心。",
        ENTP:
          "行动迅速,灵巧,有很多特长,常激励同伴,机警,坦率直言;可以站在问题的任何一方争辩，并不过分认真。善于随机应变地解决新出现的或具有挑战性的问题，但可能会忽视日常事务性的任务。兴趣转移较快。不论他们想要什么或想做什么事情，总能找到合乎逻辑的理由。",
      },
      started: true,
      leftBar: [
        {
          label: "首页",
          icon: "iling",
          url: "",
          items: [],
        },
        {
          label: "测评",
          icon: "iling",
          url: "",
          items: [
            {
              label: "职业性格测试",
              url: "",
            },
            // {
            //   label: "职业性格测试1",
            //   url: "",
            // },
            // {
            //   label: "职业性格测试2",
            //   url: "",
            // },
          ],
        },
        // {
        //   label: "职业规划",
        //   icon: "iling",
        //   url: "",
        //   items: [
        //     {
        //       label: "职业性格测试",
        //       url: "",
        //     },
        //     {
        //       label: "职业性格测试1",
        //       url: "",
        //     },
        //     {
        //       label: "职业性格测试2",
        //       url: "",
        //     },
        //   ],
        // },
      ],
      charts: [
        {
          label: "领袖型",
          type: 1, //图表类型  目前只有1（维度图）
          msg:
            "人格的很多领袖都有以下特质：豪爽、不拘小节、自视甚高、遇强越强、关心正义、公平。他们清楚自己的目标，并努力前进。由于不愿被人控制，且具有一定的支配力，所以很有潜质做领袖带领大家。由于都较好胜，有时候会对人有点攻击性，让人感到压力。",
          sides: [],
          vals: [],
        },
        {
          label: "领袖型",
          type: 2, //图表类型  目前只有1（维度图）
          msg:
            "人格的很多领袖都有以下特质：豪爽、不拘小节、自视甚高、遇强越强、关心正义、公平。他们清楚自己的目标，并努力前进。由于不愿被人控制，且具有一定的支配力，所以很有潜质做领袖带领大家。由于都较好胜，有时候会对人有点攻击性，让人感到压力。",
          sides: [],
          vals: [],
        },
        {
          label: "领袖型",
          type: 3, //图表类型  目前只有1（维度图）
          msg:
            "人格的很多领袖都有以下特质：豪爽、不拘小节、自视甚高、遇强越强、关心正义、公平。他们清楚自己的目标，并努力前进。由于不愿被人控制，且具有一定的支配力，所以很有潜质做领袖带领大家。由于都较好胜，有时候会对人有点攻击性，让人感到压力。",
          sides: [],
          vals: [],
        },
      ],
      cMsg1: {
        label: ["实用型", "研究型", "艺术型", "社会型", "企业型", "常规型"],
        msg: [
          "实用型代表的是动手能力比较强，动作也比 较协调，做事非常稳重。您比较善于操作各种仪器设备及工具；",
          "研究型代表的是非常善于思考，喜欢探索未知事物，也很擅长逻辑分析，做事非常精确，考虑问题很理性；",
          "艺术型代表的是有非常丰富的感情，创意也很多，喜欢追求浪漫的生活；",
          "社会型代表的是善于人际交往，有比较强的影响力和洞察力。您非常热情好客、重视友谊，比较关注社会问题；",
          "企业型代表的是擅长领导或说服他人，有非常大的抱负，务实。比较喜欢发表自己的意见；",
          "常规型代表的是擅长有规律、有秩序、有标准的工作。您非常遵守各项制度、做事比较谨慎，具有自我牺牲精神；",
        ],
      },

      cMsg3: {
        label: [
          "勇敢型",
          "活跃型",
          "完美型",
          "友好型",
          "和平型",
          "思考型",
          "感觉型",
          "成就型",
        ],
        msg: [
          "勇敢型的特征是：执行力好，务实，敢于挑战，相信爱拼才 会赢，说话很直接，代表动物是老⻁；",
          "活跃型的特征是：开朗、乐观、是个乐天派，相信明天会更 好，在朋友遇到挫折时，很善于鼓励，对待同学和朋友，很真诚，像鹦鹉；",
          "完美型的特征是：聪明、冷静，凡事小心严谨，喜欢探索， 善于分析，代表动物是海豚。",
          "友好型的特征是：温和、和善、执着，家和万事兴，喜欢稳 定，善于合作，像熊猫。",
          "和平型（白鸽）代表的是和平、支持、友善，善于配合， 善于人际。",
          "思考型（猫头鹰）代表的是低调、安静、镇定，选择性沟通，善于聆听，善于思考。",
          "感觉型的特征是开放、新潮，看问题比较宏观，不拘小节，同 时又善于商榷，思维是跳跃性的，代表动物是孔雀。",
          "成就型代表的是突破、变通、创新性思维、反应快速、善于 挑战，像狮子。",
        ],
      },
    };
  },

  mounted() {
    this.pageInit();
  },
  methods: {
    async pageInit() {
      await this.$http
        .get(this.$apis.getAnalysisReport, {
          userId: JSON.parse(localStorage.getItem("userInfo")).id,
        })
        .then((res) => {
          this.userReport = res.data;
        });
      this.echartInit();
    },

    startTest() {
      this.started = true;
    },
    reportExport() {
      window.print();
    },
    echartInit() {
      let this_ = this;
      this.charts.map((item) => {
        if (item.type == 1) {
          this_.echartInit1(item);
        }

        if (item.type == 2) {
          this_.echartInit2(item);
        }

        if (item.type == 3) {
          this_.echartInit3(item);
        }
      });
    },

    echartInit1(item) {
      let this_ = this;
      let max = 100;
      this.charts[0].sides = [];
      this.charts[0].vals = [];


      this.cMsg1.label.map((item, index) => {
        this_.userReport[1].respList.map((i, n) => {
          if (item == i.type) {
            this_.charts[0].sides.push(i.type);
            this_.charts[0].vals.push(i.totalScore * 16);
          }
        });
      });

      let indicator = this_.charts[0].sides.map((item) => {
        return {
          name: item,
          max: max,
        };
      });
      let data = this_.charts[0].vals;
      let chart = echarts.init(document.getElementById(`chart1`));
      chart.setOption({
        radar: {
          radius: "55%",
          name: {
            textStyle: {
              color: "#101010",
              borderRadius: 3,
              padding: [10, 10],
            },
          },
          indicator: indicator,
        },
        series: [
          {
            areaStyle: {},
            type: "radar",
            data: [
              {
                value: data,
                name: "测评结果",
              },
            ],
          },
        ],
      });
    },

    echartInit2(index) {
      let this_ = this;
      // 雷达图
      this.charts[1].sides = [];
      this.charts[1].vals = [];

      let data = [];
      this.userReport[1].respList.map((item) => {
        this_.charts[1].sides.push(item.type);
        data.push(item.totalScore);
      });

      // 哲学
      this_.charts[1].vals.push(data[2] * 10 + data[4] * 5);
      // 经济学
      this_.charts[1].vals.push(data[2] * 10 + data[0] * 5);
      // 法学
      this_.charts[1].vals.push(data[2] * 10 + data[4] * 5 + data[3] * 1);
      // 教育学
      this_.charts[1].vals.push(data[4] * 10 + data[0] * 5 + data[3] * 1);
      // 文学
      this_.charts[1].vals.push(data[4] * 10 + data[5] * 5);
      // 历史学
      this_.charts[1].vals.push(data[2] * 10 + data[4] * 5 + data[0] * 1);
      // 理学
      this_.charts[1].vals.push(data[2] * 10 + data[0] * 5 + data[1] * 1);
      // 工学
      this_.charts[1].vals.push(data[1] * 10 + data[2] * 5 + data[0] * 1);
      // 农学
      this_.charts[1].vals.push(data[1] * 10 + data[3] * 5 + data[2] * 1);
      // 医学
      this_.charts[1].vals.push(data[1] * 10 + data[2] * 5 + data[4] * 1);
      // 管理学
      this_.charts[1].vals.push(data[3] * 10 + data[4] * 5 + data[0] * 1);
      // 艺术学
      this_.charts[1].vals.push(data[5] * 10 + data[1] * 5 + data[2] * 1);

      let chart = echarts.init(document.getElementById(`chart2`));
      chart.setOption({
        xAxis: {
          type: "category",
          axisLabel: {
            interval: 0,
            // rotate: 40
          },
          data: [
            "哲学",
            "经济学",
            "法学",
            "教育学",
            "文学",
            "历史学",
            "理学",
            "工学",
            "农学",
            "医学",
            "管理学",
            "艺术学",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this_.charts[1].vals,
            type: "bar",
          },
        ],
      });
    },

    echartInit3(item) {
      let this_ = this;
      // 雷达图
      this.charts[2].sides = [];
      this.charts[2].vals = [];
      let data = [];
      this.cMsg3.label.map((item, index) => {
        this_.userReport[2].respList.map((i, n) => {
          if (item == i.type) {
            this_.charts[2].sides.push(i.type);
            this_.charts[2].vals.push(i.totalScore * 20);
            data.push({
              value: i.totalScore * 18,
              name: i.type,
            });
          }
        });
      });
      let chart = echarts.init(document.getElementById(`chart3`));
      chart.setOption({
        series: [
          {
            name: "面积模式",
            type: "pie",
            radius: "60%",
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: data,
          },
        ],
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.main-screen {
  padding: 50px 0 100px;
  display: flex;
}

.left-bar {
  width: 250px;
  padding: 0 50px;

  .bar-box {
    padding: 20px 0 20px;
    position: relative;

    ::after {
      content: "";
      position: absolute;
      display: inline-block;
      width: 175px;
      height: 1px;
      background-color: #bbb;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .bar-box:last-child {
    ::after {
      display: none;
    }
  }

  .box-label {
    font-size: 20px;
    color: #646464;
    display: flex;
    align-items: center;

    i {
      color: #ff450b;
      font-size: 20px;
      padding-right: 15px;
    }
  }

  .bar-item {
    color: #101010;
    font-size: 16px;
    padding: 6px 0 6px 35px;
  }
}

.main-wrap {
  width: 950px;
  padding-left: 50px;

  .mian-title {
    color: #939393;
    font-size: 14px;
    padding: 20px;
  }

  .main-box {
    box-shadow: 2px 2px 13px 1px rgba(0, 0, 0, 0.12);
    width: 100%;
    border-radius: 5px;
    padding: 20px 30px;
    margin-bottom: 30px;
  }

  .main-box.title {
    color: #ff450b;
    font-weight: 700;
    font-size: 28px;
    line-height: 120px;
    text-align: center;
  }
}

.time-label {
  color: #909090;
  padding-left: 80px;
  font-size: 14px;
}

.sum-box {
  display: flex;
  align-items: center;
  padding: 50px 0;
  .sun-label {
    width: 300px;
    font-weight: 700;
    text-align: center;
    position: relative;
    div {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .sun-label::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 1px;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .sun-msg {
    width: 600px;
    padding: 0 60px;
    font-size: 15px;
    color: #646464;
  }
}

.main-box.report {
  padding-bottom: 50px;
  .report-label {
    color: #505050;
    text-align: center;
    position: relative;
    font-size: 18px;
    line-height: 60px;
    .btn-export {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 80px;
      line-height: 36px;
      background-color: #ffdead;
      color: #ff450b;
      border-radius: 5px;
      border: 1px solid #ffa005;
    }
  }
  .label {
    color: #101010;
    font-size: 14px;
    font-weight: 700;
    border-left: 3px solid #101010;
    line-height: 20px;
    padding-left: 5px;
  }

  .rb {
    display: flex;
    min-height: 300px;
    align-items: center;
    .rb-l {
      width: 400px;
      height: 100%;
    }
    .rb-r {
      width: 500px;
      // padding-bottom: 75px;
      .rb-label {
        font-size: 15px;
        margin-bottom: 5px;
      }
      .rb-msg {
        color: #646464;
        font-size: 14px;
      }
    }
  }
}

.main-box.report2 {
  .report-label {
    color: #505050;
    text-align: center;
    position: relative;
    font-size: 18px;
    line-height: 60px;
    .btn-export {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 80px;
      line-height: 36px;
      background-color: #ffdead;
      color: #ff450b;
      border-radius: 5px;
      border: 1px solid #ffa005;
    }
  }
  .label {
    color: #101010;
    font-size: 14px;
    font-weight: 700;
    border-left: 3px solid #101010;
    line-height: 20px;
    padding-left: 5px;
  }

  .rb {
    min-height: 300px;
    align-items: center;
    .rb-l {
      height: 100%;
    }
  }
}
</style>