import CryptoJS from 'crypto-js';

const key = "HCSecret";

function Decrypt(word) {
    let keyHex = CryptoJS.enc.Hex.parse(key)
    let dec = CryptoJS.AES.decrypt(CryptoJS.format.Hex.parse(word), keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    let decData = CryptoJS.enc.Utf8.stringify(dec)
    return decData;
}

function Encrypt(word){
    let keyHex = CryptoJS.enc.Hex.parse(key);
    let enc = '';
    if (typeof word === 'string') {
      enc = CryptoJS.AES.encrypt(word, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      })
    } else if (typeof word === 'object') {
      let data = JSON.stringify(word)
      enc = CryptoJS.AES.encrypt(data, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      })
    }
    let encResult = enc.ciphertext.toString()
    return encResult
}

export {Decrypt,Encrypt}