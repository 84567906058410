<template>
  <div class="user-center">
    <hd></hd>
    <div class="main-screen">
      <div class="top-meun">
        <div class="menu-item" :class="{'mi-checked' : pageNo == 1}" @click="pageChange(1)">资料设置</div>
        <div class="menu-item" :class="{'mi-checked' : pageNo == 2}" @click="pageChange(2)">学生档案</div>
        <div class="menu-item">账号设置</div>
      </div>

      <div v-if="pageNo == 1" class="material-wrap ms-wrap">
        <div class="pro-msg">完善个人资料时让别人认识你的第一步</div>
        <div class="m-title">基本信息</div>
        <div class="mi-box">
          <div class="i-title m">昵称</div>
          <div class="i-input">
            <el-input v-model="input" placeholder="请输入昵称"></el-input>
          </div>
        </div>

        <div class="mi-box mi-box-l">
          <div class="i-title">头像</div>
          <div class="i-img-box">
            <img src="" alt="">
            <div class="upload-wrap">
              <div class="pro-msg">支持jpg、gif或bmp格式的图片、建议文件小于20M</div>
              <div class="btn-upload">上传文件</div>
            </div>
          </div>
        </div>

        <div class="mi-box">
          <div class="i-title">性别</div>
          <el-radio-group v-model="sex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
            <el-radio :label="0">保密</el-radio>
          </el-radio-group>
        </div>

        <div class="mi-box mi-box-l">
          <div class="i-title">个人介绍</div>
          <div class="i-text">
            <el-input type="textarea" v-model="input" :rows="4" placeholder="用一段话介绍你自己，会在你的个人页面显示，最多输入80字"></el-input>
          </div>
        </div>

        <div class="m-title">联系信息</div>

        <div class="mi-box mi-box-l">
          <div class="i-title" style="padding-top: 5px">真实姓名</div>
          <div class="i-input">
            <el-input style="width: 200px" v-model="input" placeholder="请输入真实姓名"></el-input>
            <div class="pro-msg" style="padding-top: 5px">用于高等教育课程证书登记与发放，不会以任何形式向第三方透露</div>
          </div>
        </div>

        <div class="mi-box mi-box-l">
          <div class="i-title m">常用邮箱
            <div class="change-email">修改邮箱</div>
          </div>
          <div class="i-input">
            <el-input class="email-input" style="width: 200px" v-model="input" placeholder="请输入常用邮箱"></el-input>
            <div class="pro-msg" style="padding-top: 5px"><i class="if iok ichecked"></i>用于高等教育课程证书登记与发放，不会以任何形式向第三方透露</div>
          </div>
        </div>

        <div class="mi-box mi-box-l">
          <div class="i-title" style="padding-top: 5px;position: relative;">手机号
            <div class="btn-ver">发送验证码</div>
          </div>
          <div class="i-input">
            <el-input style="width: 200px" v-model="input" placeholder="请输入手机号"></el-input>
            <div class="pro-msg" style="padding-top: 5px">用于智能填报提醒等服务，手机号不会对外公开，请放心</div>
          </div>
        </div>

        <div class="mi-box mi-box-l">
          <div class="i-title">QQ号码</div>
          <div class="i-input">
            <el-input style="width: 200px" v-model="input" placeholder="请输入QQ号码"></el-input>
            <div class="pro-msg" style="padding-top: 5px">用于填报的高效交流，方便机构及老师答疑解惑</div>
          </div>
        </div>
      </div>
      <div v-if="pageNo == 2" class="student-wrap  ms-wrap">
        <div class="pro-msg">完善个人资料时让别人认识你的第一步</div>
        <div class="m-title">学生档案</div>
        <div class="mi-box">
          <div class="i-title">预估分数</div>
          <div class="i-input">
            <el-input v-model="input" placeholder="请输入预估分数"></el-input>
          </div>
        </div>

        <div class="mi-box">
          <div class="i-title">位次</div>
          <div class="i-input">
            <el-input v-model="input" placeholder="请输入位次"></el-input>
          </div>
        </div>

        <div class="mi-box">
          <div class="i-title">选科</div>
          <div class="i-select">
            <el-select v-model="value" placeholder="请选择">
              <el-option value="1">文科</el-option>
              <el-option value="2">理科</el-option>
            </el-select>
          </div>
        </div>

        <div class="mi-box mi-box-l">
          <div class="i-title" style="padding-top:7px">城市意向</div>
          <div class="i-popover">
            <el-popover placement="bottom-start" width="770" trigger="click">
              <div class="checkbox-wrap">
                <div class="che-item" v-for="(item,index) in locList" :key="index">
                  <i @click="cheClick(item)" class="if" :class="item.sel? 'iok':'iquan'"></i>
                  <div @click="cheClick(item)">多选项{{index}}</div>
                </div>
              </div>
              <div class="btn-pop" slot="reference">选择城市</div>
            </el-popover>

            <div class="seled-wrap">
              <div class="seled-item" v-for="(item,index) in locList" :key="index">
                <div v-if="item.sel">
                  {{item.label+index}}
                  <i @click="cheClick(item)" class="if icha"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mi-box mi-box-l">
          <div class="i-title" style="padding-top:7px">专业意向</div>
          <div class="i-popover">
            <el-popover placement="bottom-start" width="770" trigger="click">
              <div class="checkbox-wrap">
                <div class="che-item" v-for="(item,index) in locList2" :key="index">
                  <i @click="cheClick(item)" class="if" :class="item.sel? 'iok':'iquan'"></i>
                  <div @click="cheClick(item)">多选项{{index}}</div>
                </div>
              </div>
              <div class="btn-pop" slot="reference">选择专业</div>
            </el-popover>
            <div class="seled-wrap">
              <div class="seled-item" v-for="(item,index) in locList2" :key="index">
                <div v-if="item.sel">
                  {{item.label+index}}
                  <i @click="cheClick(item)" class="if icha"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bot-save">
        <div class="btn-save">保存</div>
      </div>

    </div>
    <ft></ft>
  </div>
</template>
<script>
import hd from "../common/header/hd";
import ft from "../common/footer/ft";
export default {
  name: "userCenter",
  components: {
    hd,
    ft
  },
  props: {
    msg: String,
  },
  data() {
    return {
      pageNo: 1,
      sex: null,
      input: "",
      value: "",
      locList: [
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: true
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
      ],
      locList2: [
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: true
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
        {
          label: "多选项",
          sel: false
        },
      ]
    }
  },
  methods: {
    pageChange(num) {
      this.pageNo = num
    },
    cheClick(item) {
      item.sel = !item.sel
    }
  }
};
</script>

<style lang="less" scoped>

.user-center {
  background-color: #f2f2f2;
}

.main-screen {
  width: 1200px;
  margin: 50px auto 100px;
}
.top-meun {
  display: flex;
  border: 1px solid #fff;
  margin-bottom: 3px;
  background-color: #fafafa;

}
.menu-item {
  font-size: 18px;
  font-weight: 700;
  line-height: 60px;
  width: 160px;
  text-align: center;
}

.menu-item.mi-checked {
  background-color: #FF450B;
  color: #fff;
}

.ms-wrap {
  border: 1px solid #fff;
  background-color: #fafafa;
  min-height: 45vh;
  padding: 20px 35px;
  margin-bottom: 3px;
}

.pro-msg {
  color: #A9ABAE;
  font-size: 15px;
  i {
    padding-right: 10px;
  }
}

.m-title {
  padding: 30px 0;
  font-size: 26px;
}

.mi-box {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}

.mi-box-l {
  align-items: flex-start;
}
.i-title {
  width: 130px;
  padding-left: 25px;
}

.i-title.m {
  position: relative;
}

.i-title.m::after {
  display: block;
  content: "*";
  position: absolute;
  color: red;
  top: 0;
  left: 10px;
}

.change-email {
  position: absolute;
  left: 360px;
  top: 10px;
  width: 65px;
  color: #FF450B;
  font-size: 14px;
}

.btn-ver {
  position: absolute;
  left: 360px;
  top: 0;
  line-height: 40px;
  width: 120px;
  text-align: center;
  color: #fff;
  background-color: #FF450B;
  font-size: 16px;
}


.i-img-box {
  display: flex;
  img {
    height: 150px;
    width: 150px;
    background-color: #dfdfdf;
  }
}

.upload-wrap {
  padding-left: 20px;
}

.btn-upload {
  margin-top: 20px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  width: 140px;
  line-height: 40px;
  border-radius: 4px;
  text-align: center;
  font-size: 15px;
}

.i-text  {
  width: 550px;
}


i.ichecked {
  color: #FF450B;
}

.bot-save {
  padding: 50px 125px;
  border: 1px solid #fff;
  background-color: #fafafa;
}

.btn-save {
  background-color: #FF450B;
  line-height: 55px;
  width: 105px;
  font-size: 16px;
  color: #fff;
  text-align: center;
}

.btn-pop {
  width: 205px;
  line-height: 40px;
  background-color: #fff;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  display: inline-block;
}

.btn-pop {
  color: #C0C4CC;
  font-size: 14px;
  padding-left: 15px;
}

.checkbox-wrap {
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
}

.che-item {
  display: flex;
  align-items: center;
  width: 100px;
  margin: 5px 15px 5px 5px;
  div {
    padding: 0 0 2px 15px;
  }

  .iok {
    color: #FF450B;
  }
}

.seled-wrap {
  display: flex;
  padding-top: 15px;
  flex-wrap: wrap;
  width: 770px;
}

.seled-item {
  div {
    min-width: 120px;
    line-height: 30px;
    padding-left: 15px;
    font-size: 14px;
    background-color: #F0F2F5;
    margin: 0 10px 10px 0;
    color: #5A5E66;
    position: relative;
  }

  i {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #C0C4CC;
  }
}

</style>
