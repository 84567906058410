<template>
    <div class="top-menu">
        <div class="top-title">鸿云高考志愿填报系统      咨询电话：400 8557 004</div>
        <div class="user-wrap">
          <div class="user-ding" @click="handleNotifyClick"><i class="el-icon-message-solid"></i> 通知</div>
          <el-dropdown v-if="$store.getters.getAdminInfo" class="user-info">
            <div class="user-name">
              <div class="img">
                <img src="../../../../assets/img/default-user.jpg" alt="" />
              </div>
              {{ $store.getters.getAdminInfo.fullname }}<i class="el-icon-arrow-down el-icon--right"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-user-solid" @click.native="userCenter()">个人中心</el-dropdown-item>
              <el-dropdown-item icon="el-icon-switch-button" @click.native="logout()">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
</template>
<script lang="ts">
export default {
  name: "backHeader",
  props: {
    msg: String,
  },
  components: {
  },

  data() {
    return {

    };
  },
  methods: {
    handleNotifyClick(){},
    userCenter(){
      this.$router.push("/back/userCenter");
    },
    logout(){
      this.$router.replace("/back/backLogin");
      window.localStorage.clear();
      location.reload();
    },
  },
};
</script>
<style lang="less" scoped>
.top-menu {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 120px 0 35px;
    align-items: center;
    .top-title {
      letter-spacing:3px;
      color: rgba(147, 147, 147, 100);
      font-size: 16px;
    }

    .user-wrap {
      display: flex;
      align-items: center;

      .user-ding {
        color: rgba(255, 69, 11, 100);
        cursor: pointer;
        font-size: 15px;
      }
      .user-name {
          color: rgba(255, 69, 11, 100);
          font-size: 15px;
          margin-left: 20px;
          display: flex;
          align-items: center;
          .img {
            width: 25px;
            height: 25px;
            border: 1px solid #ff450b;
            border-radius: 50%;
            margin-right: 10px;
            overflow: hidden;
            img {
              height: auto;
              width: auto;
              max-width: 100%;
              max-height: 100%;
            }
          }
      }
      
    }
  }
</style>