<template>
  <div class="imore-l">
    <div class="iml-l">
      <div class="iml-t1" @click="iiSel(1)" :class="{ checked: iiid == 1 }">
        方案设计
      </div>
      <div class="iml-t2" @click="iiSel(2)" :class="{ checked: iiid == 2 }">
        信息查询
      </div>
      <div class="iml-t3" @click="iiSel(3)" :class="{ checked: iiid == 3 }">
        快捷工具
      </div>
      <div class="iml-t4" @click="iiSel(4)" :class="{ checked: iiid == 4 }">
        测评中心
      </div>
    </div>
    <div class="iml-r">
      <div class="iml-c1" v-if="iiid == 1">
        <div class="c1-i">规划师版本<i class="if iright"></i></div>
        <div class="c1-i">家长体验版<i class="if iright"></i></div>
      </div>
      <div class="iml-c2" v-if="iiid == 2">
        <div class="c2-i">
          <div>院校</div>
          <div>专业</div>
          <div>职业</div>
        </div>
        <div class="c2-i">
          <div>一分一段表</div>
          <div>省控线</div>
          <div>历史分数查询</div>
        </div>
      </div>
      <div class="iml-c3" v-if="iiid == 3">
        <div class="c3-i">
          <div>批次线查询</div>
          <div>位次查询</div>
          <div>艺术分数换算</div>
        </div>
        <div class="c3-i">
          <div>推免率</div>
          <div>硕博点</div>
          <div>招生简章查询</div>
        </div>
      </div>
      <div class="iml-c4" v-if="iiid == 4">
        <div class="c4-i">
          <div>兴趣测试</div>
          <div>性格测试</div>
          <div>能力测试</div>
        </div>
        <div class="c4-i">
          <div>意向测试</div>
          <div>价值观测试</div>
          <div @click="linkTo('/character/test')">职业性格测试</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "itemMore",
  props: {},

  data() {
    return {
      iiid: 1,
    };
  },
  props: {
    locList: Array,
  },
  methods: {
    iiSel(no) {
      this.iiid = no;
    },
    linkTo(url) {
      if (localStorage.getItem("userInfo")) {
        this.$router.push({ path: url });
      } else {
        this.$parent.loginPop = true;
      }
    },
  },
};
</script>

<style lang="less">
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.imore-l {
  color: #646464;

  display: flex;
  .iml-l {
    width: 112px;
    padding: 5px 15px;
    border-right: 2px solid #f4f5f4;
    div {
      padding: 15px 0;
      cursor: pointer;
    }

    div.checked {
      color: #101010;
      font-weight: 700;
      position: relative;
    }
    div.checked::after {
      display: block;
      content: "";
      position: absolute;
      top: 15px;
      left: 95px;
      width: 2px;
      height: 25px;
      background-color: #ff450b;
    }
  }

  .iml-r {
    width: 407px;
    padding: 30px 25px;
  }

  .iml-c1 {
    display: flex;
    .c1-i {
      width: 152px;
      line-height: 35px;
      background-color: #f8f2ed;
      font-size: 14px;
      text-align: center;
      color: #ff450b;
      margin-right: 30px;
      position: relative;
      cursor: pointer;
      i {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
  }

  .iml-c2 {
  }

  .c2-i,
  .c3-i,
  .c4-i {
    display: flex;
    padding: 10px 0;
    div {
      cursor: pointer;
      padding-right: 15px;
      margin-right: 15px;
      border-right: 1px solid #bbb;
      font-size: 14px;
    }

    div:hover {
      color: #ff450b;
    }
  }
}
</style>

