<template>
  <div class="home">
    <hd :loc-list="locationList" ></hd>
    <div class="swiper-wrap">
      <el-carousel height="60vh">
        <el-carousel-item v-for="item in swiperList" :key="item">
          <div class="swiper-item">
            <img :src="item" alt="" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="main-screen">
      <div class="search-bar">
        <div class="search-title">- 智能填报 -</div>
        <div class="search-box">
          <div class="s_province">
            <el-select v-model="province" placeholder="省份">
              <el-option
                v-for="item in locationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="s_subject">
            <el-select v-model="subject" placeholder="选科">
              <el-option
                v-for="item in subjectOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="s_score">
            <el-input v-model="score" placeholder="分数"></el-input>
          </div>
          <div class="s_rank">
            <el-input v-model="rank" placeholder="省排名"></el-input>
          </div>
          <div class="btn-search">一键生成</div>
        </div>
      </div>
      <div class="mid-btn-wrap">
        <div class="mid-btn" v-for="(item, index) in midBtnList" :key="index">
          <div class="mb-img">
            <!-- <img src="../../assets/img/h_mid_1.png" alt=""> -->
            <img :src="item.img" alt="" />
          </div>
          {{ item.label }}
        </div>
      </div>
      <div class="hot-wrap">
        <div class="hot-box" v-for="(item, index) in hotList" :key="index">
          <div class="hot-more"><span>更多</span><i class="if iplay"></i></div>
          <div class="hot-title">{{ item.title }}</div>
          <div class="hot-main">
            <div
              class="hot-item"
              v-for="(item, index) in item.hotMsg"
              :key="index"
            >
              <div class="hi-no">{{ index + 1 }}</div>
              <div class="hi-title">{{ item.label }}</div>
              <i class="if ihot"></i>
              <div class="hi-heat">{{ item.heat }}万</div>
            </div>
          </div>
        </div>
      </div>
      <div class="show-wrap">
        <div class="show-title">- 高考新资讯 -</div>
        <div class="show-more"><span>更多</span><i class="if iplay"></i></div>
        <div class="show-top">
          <div class="st-swipter">
            <el-carousel height="300px">
              <el-carousel-item v-for="(item, index) in stImg" :key="index">
                <div class="st-img">
                  <img :src="item" alt="" />
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="st-title">鼓励扩大艺术类省统考专业范围</div>
          <div class="st-cont">
            近日，教育部发布《教育部办公厅关于做好2021年普通高校部分特殊类型招生工作的通知》和《2021年普通高等学校部分特殊类型招生……
          </div>
        </div>
        <div class="show-bot">
          <div class="sb-item" v-for="(item, index) in sbList" :key="index">
            <div class="sb-img">
              <img :src="item.img" alt="" />
            </div>
            <div class="sb-title">{{ item.label }}</div>
            <div class="sb-cont">{{ item.msg }}</div>
          </div>
        </div>
      </div>
      <div class="star-wrap">
        <div class="star-title">- 名师专区 -</div>
        <el-carousel height="400px">
          <el-carousel-item v-for="(item, index) in starList" :key="index">
            <div class="star-box">
              <div class="star-item" v-for="(item, index) in item" :key="index">
                <div class="si-img">
                  <img src="../../assets/img/qy.png" alt="" />
                </div>
                <div class="si-name">{{ item.name }}</div>
                <div class="si-lv">{{ item.lv }}</div>
                <div class="si-msg">{{ item.msg }}</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <ft></ft>
  </div>
</template>
<script>
import hd from "../common/header/homehd";
import ft from "../common/footer/ft";
export default {
  name: "home",
  components: {
    hd,
    ft
  },
  props: {
    msg: String,
  },
  data() {
    return {
      isShade: false,
      province: "",
      subject: "",
      score: "",
      rank: "",
      locationList: [],
      userInfo: {
        authCode: "",
        fullname: "",
        hash: "",
        id: null,
        password: null,
        phone: "",
        region: "",
        time: "",
        username: null,
      },
      swiperList: [require("../../assets/img/home_banner.jpg")],
      midBtnList: [
        {
          label: "职业计划",
          url: "",
          img: require("../../assets/img/h_mid_1.png"),
        },
        {
          label: "升学渠道",
          url: "",
          img: require("../../assets/img/h_mid_2.png"),
        },
        {
          label: "查院校",
          url: "",
          img: require("../../assets/img/h_mid_3.png"),
        },
        {
          label: "查专业",
          url: "",
          img: require("../../assets/img/h_mid_4.png"),
        },
        {
          label: "方案设计",
          url: "",
          img: require("../../assets/img/h_mid_5.png"),
        },
      ],

      hotList: [
        {
          title: "大学排行",
          hotMsg: [
            {
              label: "清华大学",
              heat: "4.3",
            },
            {
              label: "清华大学美术学院",
              heat: "3.4",
            },
            {
              label: "清华大学医学部",
              heat: "2.7",
            },
            {
              label: "北京大学",
              heat: "2.1",
            },
            {
              label: "北京大学医学部",
              heat: "1.9",
            },
            {
              label: "浙江大学",
              heat: "1.4",
            },
          ],
        },
        {
          title: "专业排行",
          hotMsg: [
            {
              label: "金融学",
              heat: "4.3",
            },
            {
              label: "计算机科学与技术",
              heat: "3.4",
            },
            {
              label: "软件工程",
              heat: "2.7",
            },
            {
              label: "财务管理",
              heat: "2.1",
            },
            {
              label: "会计学",
              heat: "1.9",
            },
            {
              label: "汉语言学",
              heat: "1.4",
            },
          ],
        },
        {
          title: "热门评测",
          hotMsg: [
            {
              label: "兴趣评测Interest",
              heat: "4.3",
            },
            {
              label: "性格评测Character",
              heat: "3.4",
            },
            {
              label: "性格评测Ability",
              heat: "2.7",
            },
            {
              label: "价值观评测Value",
              heat: "2.1",
            },
            {
              label: "职业性格测试APESK",
              heat: "1.9",
            },
            {
              label: "108题九型人格测试",
              heat: "1.4",
            },
          ],
        },
      ],
      stImg: [
        require("../../assets/img/hb-1.png"),
        require("../../assets/img/hb-2.png"),
      ],

      sbList: [
        {
          label: "2021高考报名启动",
          msg: "这些地方的高考将有新变化！",
          img: require("../../assets/img/hb-1.png"),
        },
        {
          label: "Python爬虫工程师",
          msg: "打造全链路，成为独当一面的核心设计师",
          img: require("../../assets/img/hb-2.png"),
        },
        {
          label: "数据分析师（Python）",
          msg: "打造全链路，成为独当一面的核心设计师",
          img: require("../../assets/img/hb-3.png"),
        },
        {
          label: "互联网运营",
          msg: "打造全链路，成为独当一面的核心设计师",
          img: require("../../assets/img/hb-4.png"),
        },
      ],

      starList: [
        [
          {
            name: "秋叶",
            lv: "中共党员，本科（或硕士）学历",
            msg: `1999年，被聘为全国教育科学“九五”规划国家教委重点研究课题《教育信息资源的优化配置与高效开发》的子课题《边疆少数民族地区教育信息资源的合理配置与高效开发》课题组成员……`,
            img: "",
          },

          {
            name: "秋叶",
            lv: "中共党员，本科（或硕士）学历",
            msg: `1999年，被聘为全国教育科学“九五”规划国家教委重点研究课题《教育信息资源的优化配置与高效开发》的子课题《边疆少数民族地区教育信息资源的合理配置与高效开发》课题组成员……`,
            img: "",
          },

          {
            name: "秋叶",
            lv: "中共党员，本科（或硕士）学历",
            msg: `1999年，被聘为全国教育科学“九五”规划国家教委重点研究课题《教育信息资源的优化配置与高效开发》的子课题《边疆少数民族地区教育信息资源的合理配置与高效开发》课题组成员……`,
            img: "",
          },

          {
            name: "秋叶",
            lv: "中共党员，本科（或硕士）学历",
            msg: `1999年，被聘为全国教育科学“九五”规划国家教委重点研究课题《教育信息资源的优化配置与高效开发》的子课题《边疆少数民族地区教育信息资源的合理配置与高效开发》课题组成员……`,
            img: "",
          },
        ],
        [
          {
            name: "秋叶",
            lv: "中共党员，本科（或硕士）学历",
            msg: `1999年，被聘为全国教育科学“九五”规划国家教委重点研究课题《教育信息资源的优化配置与高效开发》的子课题《边疆少数民族地区教育信息资源的合理配置与高效开发》课题组成员……`,
            img: "",
          },

          {
            name: "秋叶",
            lv: "中共党员，本科（或硕士）学历",
            msg: `1999年，被聘为全国教育科学“九五”规划国家教委重点研究课题《教育信息资源的优化配置与高效开发》的子课题《边疆少数民族地区教育信息资源的合理配置与高效开发》课题组成员……`,
            img: "",
          },

          {
            name: "秋叶",
            lv: "中共党员，本科（或硕士）学历",
            msg: `1999年，被聘为全国教育科学“九五”规划国家教委重点研究课题《教育信息资源的优化配置与高效开发》的子课题《边疆少数民族地区教育信息资源的合理配置与高效开发》课题组成员……`,
            img: "",
          },

          {
            name: "秋叶",
            lv: "中共党员，本科（或硕士）学历",
            msg: `1999年，被聘为全国教育科学“九五”规划国家教委重点研究课题《教育信息资源的优化配置与高效开发》的子课题《边疆少数民族地区教育信息资源的合理配置与高效开发》课题组成员……`,
            img: "",
          },
        ],
      ],
      provinceOpts: [],
      subjectOpts: [
        {
          value: "1",
          label: "土木",
        },
        {
          value: "2",
          label: "计算机",
        },
        {
          value: "3",
          label: "财会",
        },
        {
          value: "4",
          label: "建筑",
        },
        {
          value: "5",
          label: "外语",
        },
      ],
    };
  },
  mounted() {
    this.getLocation();
  },
  methods: {
    login() {
      this.isShade = true;
    },
    getLocation() {
      this.$http.post(this.$apis.getLocationList, {}).then((res) => {
        this.$store.commit("setLocationList", res.data.list);
        this.locationList = res.data.list.map((item) => {
          return {
            value: item.name,
            label: item.name,
          };
        });
      });
    },
  },
};
</script>

<style lang="less">
.s_province,
.s_subject {
  .el-input__inner {
    background-color: #ff450b;
    border: 1px solid #fff;
    color: #fff;
  }
  .el-select .el-input.is-focus .el-input__inner,
  .el-input__inner:hover,
  .el-select .el-input__inner:focus {
    border: 1px solid #fff;
  }
  .el-select .el-input .el-select__caret {
    color: #fff;
  }

  input::-webkit-input-placeholder {
    color: #fff;
  }
  input::-moz-input-placeholder {
    color: #fff;
  }
  input::-ms-input-placeholder {
    color: #fff;
  }
}

.s_score,
.s_rank {
  .el-input__inner {
    border: none;
    border-radius: 19px;
    color: #f82a2a;
  }
  input::-webkit-input-placeholder {
    color: #f82a2a;
  }
  input::-moz-input-placeholder {
    color: #f82a2a;
  }
  input::-ms-input-placeholder {
    color: #f82a2a;
  }
}
.home {
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #f82a2a;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #f82a2a;
    border-color: #f82a2a;
  }

  .el-checkbox__inner:hover,
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #f82a2a;
  }
}
</style>

<style lang="less" scoped>
.swiper-wrap {
  .swiper-item {
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.main-screen {
  width: 1200px;
  margin: 45px auto 0;
}

.search-bar {
  height: 200px;
  background-color: #ff450b;
}

.search-title {
  line-height: 60px;
  text-align: center;
  color: #fff;
  font-size: 25px;
}

.search-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 120px;
  padding:  0 75px;
}

.s_province,
.s_subject {
  width: 160px;
  height: 40px;
}

.s_score,
.s_rank {
  width: 140px;
  height: 40px;
}

.btn-search {
  width: 180px;
  margin-left: 75px;
  line-height: 50px;
  color: #fff;
  border-radius: 25px;
  border: 2px solid #fff;
  background-color: #f82a2a;
  text-align: center;
  cursor: pointer;
}

.mid-btn-wrap {
  padding: 144px 100px;
  height: 450px;
  display: flex;
  justify-content: space-around;
}

.mid-btn {
  text-align: center;
}

.mb-img {
  width: 120px;
  height: 120px;
  border: 2px solid #ff450b;
  border-radius: 50%;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
  }
}

.hot-wrap {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 200px;
}
.hot-box {
  background-color: #ff450b;
  width: 27%;
  height: 400px;
  border-radius: 10px;
  padding: 20px;
}

.hot-box:nth-child(2n) {
  background-color: #f82a2a;
}

.hot-more {
  display: flex;
  justify-content: flex-end;
  span {
    line-height: 26px;
    padding-right: 7px;
    color: #fff;
    font-size: 14px;
  }
  i {
    color: #fff;
    font-size: 24px;
  }
}

.hot-title {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  padding: 15px 40px 50px;
}

.hot-main {
  color: #ffff;
}

.hot-item {
  display: flex;
  align-items: center;
  padding: 5px 0;

  .if {
    color: transparent;
    font-size: 20px;
  }
}

.hi-no {
  width: 24px;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  text-align: center;
}

.hot-item:nth-child(-n + 3) .hi-no {
  background-color: #fff;
  color: #ff450b;
  border-radius: 3px;
}

.hot-item:nth-child(-n + 3) .if {
  color: #ffff13;
}

.hi-title {
  width: 200px;
  padding-left: 15px;
  font-size: 15px;
}
.hi-heat {
  font-size: 14px;
}
.show-wrap {
  height: 1000px;
}

.show-title,
.star-title {
  text-align: center;
  color: #646464;
  font-size: 24px;
}

.show-top {
  width: 500px;
  margin: 0 auto;
}

.st-img {
  height: 300px;
  position: relative;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
  }
}

.show-more {
  display: flex;
  justify-content: flex-end;
  color: #ff450b;
  span {
    line-height: 26px;
    padding-right: 7px;
    font-size: 14px;
  }
  i {
    font-size: 20px;
  }
}

.st-swipter {
}

.st-title {
  color: #101010;
  padding: 10px 0 20px;
}
.st-cont {
  width: 80%;
  color: #8f8e94;
  font-size: 16px;
}

.show-bot {
  display: flex;
  justify-content: space-around;
  padding-top: 50px;
}

.sb-item {
  width: 23%;

  .sb-img {
    background-color: #dfdfdf;
    width: 100%;
    height: 155px;
    border-radius: 5px;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.sb-title {
  color: #101010;
  padding: 10px 10px 20px;
}
.sb-cont {
  padding: 0 10px;
  width: 80%;
  color: #8f8e94;
  font-size: 16px;
}
.star-wrap {
  height: 500px;
  margin-bottom: 200px;
}

.star-title {
  margin-bottom: 50px;
}
.star-box {
  display: flex;
  justify-content: space-around;
}
.star-item {
  width: 240px;
}

.si-img {
  width: 120px;
  height: 120px;
  margin: 0 auto 30px;
  border-radius: 50%;
  position: relative;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }
}

.si-name {
  text-align: center;
  color: #101010;
  font-size: 20px;
}

.si-lv {
  color: #8b8d92;
  text-align: center;
  font-size: 15px;
  padding: 20px 10px;
}

.si-msg {
  color: #8b8d92;
  font-size: 15px;
}
</style>
