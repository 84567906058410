<template>
  <div class="back-login">
    <div class="logo"><img src="../../assets/img/logo-new.jpg" alt=""/></div>
    <div class="login-wrap">
      <div class="main-title">管理员系统</div>
      <div v-if="!isTelLogin">
        <el-input class="bl-input" v-model="userName" placeholder="请输入您的用户名" @keyup.enter.native="login">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
        <el-input class="bl-input" v-model="password" placeholder="请输入您的密码" type="password" @keyup.enter.native="login">
          <i slot="prefix" class="el-input__icon el-icon-lock"></i>
        </el-input>
      </div>
      <div v-if="isTelLogin">
        <el-input  class="bl-input" v-model="tel" placeholder="请输入您的手机号" type="text" >
          <i slot="prefix" class="el-input__icon el-icon-phone"></i>
        </el-input>
        <el-input class="bl-input" v-model="authCode" placeholder="请输入验证码" type="text" >
          <i slot="prefix" class="el-input__icon el-icon-s-comment"></i>
          <el-button v-show="!isTime" slot="append" @click="getAuthCode()">获取验证码</el-button>
          <span v-show="isTime" slot="append" >{{count}}秒后重新获取</span>
        </el-input>
        
      </div>
      
      <el-checkbox v-if="!isTelLogin" v-model="remember">记住密码</el-checkbox>
      <div class="btn-login" @click="login()">登录</div>
      <div class="bot-wrap">
        <div @click="reg()" class="text-btn">注册账号</div>
        <div v-if="!isTelLogin" @click="telLogin()" class="text-btn">手机验证码登录</div>
        <div v-else @click="accountLogin()" class="text-btn">账号/密码登录</div>
      </div>
    </div>
  </div>
</template>
<script>
import {Encrypt} from "../../utils/secret";
export default {
  name: "backLogin",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      userName:"",
      password:"",
      tel:"",
      authCode:"",
      time:"",
      hash:"",
      remember:false,
      isTime:false,
      count:60,
      isTelLogin:false,
      timer:null
    };
  },
  methods: {
    login(){
      let loading = this.$loading({ fullscreen: true });
      //账号密码登录
      if(!this.isTelLogin){
        if(!this.userName || this.userName === "" || !this.password || this.password === ""){
          this.$message.error("请填写正确信息！");
          loading.close();
          return;
        }
        this.$http.post(this.$apis.backLogin+"?username="+this.userName+"&password="+this.password).then((res) => {
          loading.close();
          //因为成功时不返回code所以这么判断
          if (res.id && res.userType && res.userType==1) {
            var enc = Encrypt(JSON.stringify(res));
            localStorage.setItem("adminInfo", enc);
            this.$message.success("登录成功!");
            this.$router.replace("/back/index");
          }else if(res.userType && res.userType!=1){
            this.$message.error("该账号不可用!");
            return;
          }
          else{
            this.$message.error(res.message);
            return;
          }
        });
      }
      //手机号登录
      else{
        if(!this.tel || this.tel ==="" || !this.authCode || this.authCode ===""){
          this.$message.error("请填写正确信息！");
          loading.close();
          return;
        }
        if(this.time === "" || this.hash === ""){
          this.$message.error("请重新获取验证码！");
          loading.close();
          return;
        }
        var data ={
          authCode: this.authCode,
          hash: this.hash,
          phone: this.tel,
          time: this.time,
        };
        this.$http.post(this.$apis.backLoginByTel,data).then((res) => {
          loading.close();
          if (res.id && res.userType && res.userType==1) {
            var enc = Encrypt(JSON.stringify(res));
            localStorage.setItem("adminInfo", enc);
            this.$message.success("登录成功!");
            this.$router.replace("/back/index");
          }else if(res.userType && res.userType!=1){
            this.$message.error("该账号不可用!");
          }
          else if (res.code === 400){
            this.$message.error("手机号未注册或验证码错误,请重试!");
          }else{
            this.$message.error(res.message);
          }
        });
      }
    },
    reg(){
      this.$router.push("/back/backRegister");
    },
    telLogin(){
      this.isTelLogin = true;
    },
    accountLogin(){
      this.isTelLogin = false;
    },
    getAuthCode(){
      if(!this.vertifyTel(this.tel)){
        this.$message({
          message: "手机号错误,请重新填写!",
          type: "error",
        });
        return;
      }
      this.$http.get(this.$apis.getAuthCode+"?phone="+this.tel).then((res) => {
        if (res) {
          this.hash = res.hash;
          this.time = res.time;
          this.$message.success("验证码已发送,请注意查收!");
        }else{
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      });
      const timeCount = 60;
      if(!this.timer){
        this.count= timeCount;
        this.isTime = true;
        this.timer = setInterval(() =>{
            if(this.count>0 && this.count <= timeCount){
              this.count--;
            }else{
              this.isTime = false;
              clearInterval(this.timer);
              this.timer = null;
            }
        },1000);
      }
    },
    vertifyTel(tel){
      return !(!tel || tel === '' || !/^1\d{10}$/.test(tel));
    }
  },
};
</script>
<style lang="less">
.bl-input .el-input__inner {
  background-color: #e6e6e6;
}
</style>

<style lang="less" scoped>
.back-login {
  background: url("../../assets/img/back-index-bg.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}
.logo {
    height: 120px;
    width: 300px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: auto;
      height: auto;
      max-width: 60%;
      max-height: 60%; 
    }
}
.login-wrap {
  position:absolute;
  top: 15%;
  left:10%;
  width: 735px;
  height: 465px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 100);
  padding: 78px 122px 0;
}

.main-title {
  color: rgba(0, 0, 0, 1);
  font-size: 29px;
  text-align: center;
  margin: 0 auto 25px;
  font-weight: 700;
  border-bottom: 3px solid #ff450b;
  width: 165px;
  letter-spacing: 4px;
}

.bl-input {
  margin-bottom: 15px;
}

.btn-login {
  cursor: pointer;
  width: 318px;
  line-height: 48px;
  border-radius: 3px;
  background-color: rgba(255, 69, 11, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 20px;
  text-align: center;
  margin: 15px auto 27px;
}
.bot-wrap {
  display: flex;
  position: relative;
  justify-content: space-between;
  .text-btn {
    cursor: pointer;
    color: rgba(248, 42, 42, 100);
    font-size: 16px;
  }
}
.bot-wrap::after {
  position: absolute;
  display: block;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 1px;
  height: 25px;
  background-color: #bbb;
}
@media (max-width: 33.9em){
  .logo {
    width: 100%;
  }
  .login-wrap {
    width: 100%;
    padding: 20px 22px;
    top: 12%;
    left: 0%;
    height: 365px;
  }
  .main-title {
    font-size: 20px;
    width: 140px;
    letter-spacing: 3px;
  }
  .btn-group{
    .btn {
        width: 160px;
        font-size: 14px;
    }
    .btn-login {
        width: 160px;
        font-size: 16px;
    }
  }
}
</style>
