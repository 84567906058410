import Vue from 'vue';
import router from "./router/index";
import axios from './request';
import Vuex from 'vuex';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/fonts/iconfont.css';
import './assets/css/base.less';
import './assets/css/common.less';
import apis from './api/index';
import App from './App.vue';
import * as echarts from 'echarts';
import store from './store/store.js';
import VueCookies from 'vue-cookies';

Vue.prototype.$http = axios;
Vue.prototype.$apis = apis;

Vue.use(Vuex);
Vue.prototype.$store = store;

Vue.use(ElementUI);
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
// axios.defaults.baseURL = '/api';
// axios.defaults.headers.post['Content-Type'] = 'application/json';
Vue.use(VueCookies)


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

document.title = "鸿云高考志愿填报系统"