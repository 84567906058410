<template>
  <div class="f-box">
    <div class="footer">
      <div style="width:400px">
        <div class="tel-msg">服务热线：</div>
        <div class="tel-val">400-999-3030</div>
        <div class="tb-msg">AI智能填报真诚为您提供人工咨询服务</div>
        <div class="tbb-wrap">
          <div class="tbb-i">关于我们 丨</div>
          <div class="tbb-i">联系我们 丨</div>
          <div class="tbb-i">帮助中心</div>
        </div>
        <div class="f1b-msg">©1997-2019 xxxxxxxx 版权所有</div>
      </div>
      <div class="f2-img">
        <img src="" alt="">
      </div>
      <div class="f3">
        高考报考，<br>
        就选AI智能填报
      </div>
      <div class="f4">
        <div class="f4i-wrap">
          <span>关注我们:</span>
          <i class="if iweixin"></i>
          <i class="if iweibo"></i>
        </div>
        <div class="f4-qr">
          <img src="" alt="">
        </div>
        <div class="f4qb">官方微信</div>
      </div>

    </div>
  </div>

</template>
<script>
export default {
  name: "ft",
  props: {
    msg: String,
  },
};
</script>

<style lang="less" scoped>
.f-box {
  background-color: #474443;
}
.footer {
  height: 400px;
  width: 1200px;
  margin:0 auto;
  display: flex;
  justify-content: space-between;
  padding: 80px 0 0;
  color: #fff;
}

.tel-msg {
  font-size: 16px;
}

.tel-val {
  font-size: 50px;
  font-weight: 700;
  color: #FF450B;
}

.tb-msg {
  font-size: 18px;
  padding: 10px 0 70px;
}

.tbb-wrap {
  display: flex;
}

.tbb-i {
  width: 175px;
}

.f1b-msg {
  color: #8B8D92;
  padding-top: 30px;
}
.f2-img {
  position: relative;
  padding-top: 60px;
  img {
    width: 265px;
    height: 160px;
    background-color: #dfdfdf;
  }
}

.f2-img::after {
  top: 40px;
  right: -65px;
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 220px;
  background-color: #bbb;
}

.f3 {
  padding: 100px 0 0 70px;
}

.f4i-wrap {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 0 0 30px;

  span {
    padding-right: 20px;
  }

  i {
    padding-right: 15px;
    font-size: 30px;
  }
}

.f4-qr {
  width: 190px;
  height: 190px;
  background-color: #dfdfdf;
}

.f4qb {
  padding: 20px;
  text-align: center;
}
</style>
