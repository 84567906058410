<template>
  <div class="back-reg">
    <div class="logo"><img src="../../assets/img/logo-new.jpg" alt=""/></div>
    <div class="reg-wrap">
      <div class="main-title">用户注册</div>
      <label>账号:<span class="required">*</span></label>
      <el-input class="bl-input" v-model="userName" placeholder="请输入账号"></el-input>
      <label>密码:<span class="required">*</span></label>
      <el-input class="bl-input" v-model="password" placeholder="请输入密码" type="password"></el-input>
      <label v-show="password">确认密码:<span class="required">*</span></label>
      <el-input class="bl-input" v-show="password" v-model="confirmPassword" placeholder="请再次输入密码" type="password"></el-input>
      <label>真实姓名:</label>
      <el-input class="bl-input" v-model="realName" placeholder="请输入真实姓名"></el-input>
      <label>手机号:<span class="required">*</span></label>
      <el-input class="bl-input" v-model="tel" placeholder="请输入手机号"></el-input>
      <label>验证码:<span class="required">*</span></label>
      <el-input class="bl-input" v-model="authCode" placeholder="请输入验证码" type="text">
        <el-button v-show="!isTime" slot="append" @click="getAuthCode()">获取验证码</el-button>
        <span v-show="isTime" slot="append" >{{count}}秒后重新获取</span>
      </el-input>
      <div class="btn-group">
        <div class="btn" @click="back()">返回</div>
        <div class="btn-login" @click="register()">注册</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "backLogin",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      userName:"",
      password:"",
      confirmPassword:"",
      realName:"",
      tel:"",
      authCode:"",
      hash:"",
      time:"",
      isTime:false,
      count:60,
      timer:null
    };
  },
  methods: {
    register(){
      let loading = this.$loading({ fullscreen: true });
      if(!this.vertifyForm()){
        this.$message.error("请填写正确信息！");
        loading.close();
        return;
      }
      if(this.password != this.confirmPassword){
        this.$message.error("两次密码输入不一致!");
        loading.close();
        return;
      }
      if(this.time === "" || this.hash === ""){
        this.$message.error("请重新获取验证码！");
        loading.close();
        return;
      }
      var data ={
        adminType: 1,
        authCode: this.authCode,
        fullname: this.realName,
        hash: this.hash,
        password: this.password,
        phone: this.tel,
        studentCount: 1,
        time: this.time,
        userType: 1,
        username: this.userName
      };
      this.$http.post(this.$apis.backRegister,data).then((res) => {
        loading.close();
        if (res == true) {
          this.$message.success("注册成功!");
          this.$router.replace("/back/backLogin");
        }else if(res && res.code != 200){
          this.$message.error(res.message);
        }else{
          this.$message.error("未知原因注册失败! 请稍后重试!");
        }
      });
    },
    getAuthCode(){
      if(!this.vertifyTel(this.tel)){
        this.$message({
          message: "手机号错误,请重新填写!",
          type: "error",
        });
        return;
      }
      this.$http.get(this.$apis.getAuthCode+"?phone="+this.tel).then((res) => {
        if (res) {
          this.hash = res.hash;
          this.time = res.time;
          this.$message.success("验证码已发送,请注意查收!");
        }else{
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      });
      const timeCount = 60;
      if(!this.timer){
        this.count= timeCount;
        this.isTime = true;
        this.timer = setInterval(() =>{
            if(this.count>0 && this.count <= timeCount){
              this.count--;
            }else{
              this.isTime = false;
              clearInterval(this.timer);
              this.timer = null;
            }
        },1000);
      }
    },
    vertifyForm(){
      if(!this.userName || this.userName === ""){
        return false;
      }
      if(!this.password || this.password === ""){
        return false;
      }
      if(!this.tel || this.tel === ""){
        return false;
      }
      if(!this.authCode || this.authCode === ""){
        return false;
      }
      return true;
    },
    vertifyTel(tel){
      return !(!tel || tel === '' || !/^1\d{10}$/.test(tel));
    },
    back(){
        this.$router.go(-1);
    }
  },
};
</script>
<style lang="less">
.bl-input .el-input__inner {
  background-color: #e6e6e6;
}
</style>

<style lang="less" scoped>
.back-reg {
  background: url("../../assets/img/back-index-bg.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}
.logo {
    height: 120px;
    width: 300px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: auto;
      height: auto;
      max-width: 60%;
      max-height: 60%; 
    }
}
.reg-wrap {
  width: 735px;
  min-height: 465px;
  background-color: #fff;
  position:absolute;
  top: 15%;
  left:10%;
  border-radius: 3px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 100);
  padding: 78px 122px;
  label{
    font-size: 14px;
    .required{
      color: #ff450b;
    }
  }
}

.main-title {
  color: rgba(0, 0, 0, 1);
  font-size: 29px;
  text-align: center;
  margin: 0 auto 25px;
  font-weight: 700;
  border-bottom: 3px solid #ff450b;
  width: 165px;
  letter-spacing: 4px;
}

.bl-input {
  margin-bottom: 8px;
}
.btn-group{
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    line-height: 48px;
    .btn {
        cursor: pointer;
        width: 240px;
        border-radius: 3px;
        background-color: rgb(219, 219, 219);
        color: rgba(255, 255, 255, 100);
        font-size: 20px;
        text-align: center;
    }
    .btn-login {
        cursor: pointer;
        width: 240px;
        border-radius: 3px;
        background-color: rgba(255, 69, 11, 100);
        color: rgba(255, 255, 255, 100);
        font-size: 20px;
        text-align: center;
    }
}

.bot-wrap {
  display: flex;
  position: relative;
  justify-content: space-between;
  div {
    color: rgba(248, 42, 42, 100);
    font-size: 16px;
  }
}
.bot-wrap::after {
  position: absolute;
  display: block;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 1px;
  height: 25px;
  background-color: #bbb;
}

@media (max-width: 33.9em){
  .logo {
    width: 100%;
  }
  .reg-wrap {
    width: 100%;
    padding: 20px 22px;
    top: 12%;
    left: 0%;
  }
  .main-title {
    font-size: 20px;
    width: 140px;
    letter-spacing: 3px;
  }
  .btn-group{
    .btn {
        width: 160px;
        font-size: 14px;
    }
    .btn-login {
        width: 160px;
        font-size: 16px;
    }
  }
}
</style>
