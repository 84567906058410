import Vue from 'vue';
import Vuex from 'vuex';
import {Decrypt} from '../utils/secret';
Vue.use(Vuex);

let store = new Vuex.Store({
    // 1. state
    state: {
        city: "城市名",
        locationList: [],
        userInfo: {},
        loginPop: false,
        adminInfo:{},

        featureList:[],
        provinceList:[],
        cityList:[],
        schoolTypeList:[],
        schoolLevelList:[],
        schoolNatureList:[],
    },

    // // 2. getters
    getters: {
        // 参数列表state指的是state数据
        getCityFn(state) {
            return state.city;
        },
        getLocationList(state) {
            return state.locationList;
        },
        getUserInfo(state) {
            return state.userInfo;
        },
        getLoginPop(state) {
            return state.loginPop;
        },
        getAdminInfo(){
            var adminInfo = Decrypt(localStorage.getItem("adminInfo"));
            return JSON.parse(adminInfo);
        },

        getFeatureList(state) {
            return state.featureList; 
        },
        getProvinceList(state) {
            return state.provinceList; 
        },
        getCityList(state) {
            return state.cityList; 
        },
        getSchoolTypeList(state) {
            return state.schoolTypeList; 
        },
        getSchoolLevelList(state) {
            return state.schoolLevelList; 
        },
        getSchoolNatureList(state) {
            return state.schoolNatureList; 
        },
    },
    // 3. actions
    // 通常跟api接口打交道
    actions: {
        // 设置城市信息
        // 参数列表：{commit, state}
        // state指的是state数据
        // commit调用mutations的方法 
        // name就是调用此方法时要传的参数
        setCityName({
            commit,
            state
        }, name) {
            // 跟后台打交道
            // 调用mutaions里面的方法
            commit("setCity", name);
        },

    },
    // 4. mutations
    mutations: {
        // state指的是state的数据
        // name传递过来的数据
        setCity(state, name) {
            state.city = name; //将传参设置给state的city
        },
        setLocationList(state, val) {
            state.locationList = val; 
        },
        setUserInfo(state, val) {
            state.userInfo = val; 
        },
        setLoginPop(state, val) {
            state.loginPop = val; 
        },

        setFeatureList(state, val) {
            state.featureList = val; 
        },
        setProvinceList(state, val) {
            state.provinceList = val; 
        },
        setCityList(state, val) {
            state.cityList = val; 
        },
        setSchoolTypeList(state, val) {
            state.schoolTypeList = val; 
        },
        setSchoolLevelList(state, val) {
            state.schoolLevelList = val; 
        },
        setSchoolNatureList(state, val) {
            state.schoolNatureList = val; 
        },
    }
});

export default store;