<template>
  <div class="planner">
    <hd></hd>
    <breadcrumb></breadcrumb>

    <div class="main-screen">
      <div class="top-info">
        <img class="info-img" src="../../assets/img/planner.png" alt="">
        <div class="info-wrap">
          <div class="info-title">
            <p>专业规划师1v1填报</p>
            <div><span><i class="if ishare"></i></span><span><i class="if istar-b"></i></span></div>
          </div>
          <div class="peo-no">
            <span>{{1938}}人咨询过</span>
            <i class="if istar" v-for="item in 5" :key="item"></i>
          </div>
          <div class="money">
            现价：
            <span>电话咨询</span>
          </div>
          <div class="pro-msg">
            服务方式:AI高考平台<br>
            服务对象：高三生、高二生、高一生
          </div>
          <div class="btn-wrap">
            <div class="btn-apply">现在报名</div>
            <div class="btn-adv">在线咨询</div>
          </div>
        </div>
      </div>
      <div class="page-change-wrap">
        <div class="pc-item checked">介绍</div>
        <div class="pc-item">服务</div>
        <div class="pc-item">师资团队</div>
        <div class="pc-item">服务评价</div>
      </div>
      <div class="show-page">
        <div class="show-left">
          <div class="show-title">- 介绍 -</div>
          <div style="height: 200px"></div>
          <div class="show-title">- 服务 -</div>
          <div style="height: 200px"></div>
          <div class="show-title">- 师资团队 -</div>
          <div class="teacher-show">
            <div class="ts-item" v-for="item in 4" :key="item">
              <div class="ts-img"><img src="../../assets/img/qy.png" alt=""></div>
              <div class="ti-name">秋叶</div>
              <div class="ti-msg">曾荣获校奖教金特等奖、霍英东教育基金会高等学校优秀青年教师奖、江苏省“红杉树”园丁奖,并被评为“全国优秀教师”、江苏省高校“教学名师”,享受政府特殊津贴。</div>
            </div>
          </div>
        </div>
        <div class="show-right">
          <div class="rt-wrap">
            <div class="rt-img"><img src="../../assets/img/ws.png" alt=""></div>
            <div class="rt-name">吴帅</div>
            <div class="rt-msg">南京师范大学毕业，“省级优秀教师”称号，多年来致力于音乐教育事业的同时，悉心研究钢琴教学，培养了大批的优秀钢琴学生，他善于挖掘学生个性，因材施教，注重基本功，灵活耐心，具有很强的亲和力，在学生和家长中拥有良好的口碑，其所带学生在历年的考级和各类艺术比赛中成绩斐然。</div>
          </div>
          <div class="rb-wrap">
            <div class="rb-title">咨询</div>
            <div class="rb-cont">
              <div class="rb-info">
                <div class="rb-img"><img src="../../assets/img/ws.png" alt=""></div>
                <div class="rb-name">吴帅</div>
                <div class="btn-ad">咨询</div>
              </div>
              <div class="rb-msg">可在此在线咨询关于产品的内容、价格、售前、售后服务的相关信息</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ft></ft>
  </div>
</template>
<script>
import hd from "../common/header/hd";
import ft from "../common/footer/ft";
import breadcrumb from "../common/breadcrumb/breadcrumb";
export default {
  name: "planner",
  components: {
    hd,
    ft,
    breadcrumb
  },
  props: {
    msg: String,
  },
  data() {
    return {

    }
  },
};
</script>

<style lang="less" scoped>
.planner {
    background-color: #f2f2f2;
}

.main-screen {
    width: 1200px;
    margin: 0 auto;
}

.top-info {
    height: 375px;
    padding: 43px;
    background-color: #fff;
    display: flex;
}

.info-img {
    width: 450px;
    height: 250px;
    margin-right: 100px;
}
.info-wrap {
  width: 540px;
}
.info-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  p {
    font-size: 30px;
    font-weight: 700;
  }

  div{
    // border: 1px solid #bbb;
  }
  span {
    display: inline-block;
    width: 60px;
    text-align: center;
    border: 1px solid #bbb;
    padding: 5px;
  }
  i {
    color: #bbb;
    font-size: 22px;
  }
}

.peo-no {
  span {
    font-size: 14px;
    color: #8F8E94;
    padding-right: 60px;
  }
  i {
    color: #FF450B;
  }
}

.money {
  padding: 35px 0;
  span {
    color: #FF450B;
  }
}

.pro-msg {
  color: #8F8E94;
}

.btn-wrap {
  display: flex;
  margin-top: 20px;
}

.btn-apply,.btn-adv {
  width: 150px;
  line-height: 50px;
  text-align: center;
  background-color: #FF450B;
  color: #fff;
  margin-right: 30px;
}

.page-change-wrap {
  display: flex;
  background-color: #fafafa;
  padding: 10px 50px;
  margin-bottom: 30px;
}

.pc-item {
  width: 80px;
  line-height: 40px;
  text-align: center;
  margin-right: 50px;
  color: #8F8E94;
  border-radius: 3px;
}

.pc-item.checked {
  background-color: #ff450b;
  color: #fff;
}

.show-page {
  display: flex;
}

.show-left {
  width: 800px;
  margin-right: 50px;
  padding: 40px 50px;
  background-color: #fff;
}

.show-title {
  color: #646464;
  font-size: 22px;
  text-align: center;
}

.teacher-show {
  display: flex;
  justify-content: space-between;
}
.ts-item {
  width: 23%;
  padding-top: 30px;
}

.ts-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}

.ti-name {
  font-size: 16px;
  text-align: center;
  padding: 10px 0 15px;
}

.ti-msg {
  font-size: 14px;
  color:#8B8D92;
}


.show-right {
  width: 350px;
}

.rt-wrap {
  background-color: #fff;
  margin-bottom: 30px;
  padding-top: 50px;
}

.rt-img {
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
  }
}

.rt-name {
  text-align: center;
  font-weight: 700;
  padding: 20px 0 30px;
}

.rt-msg {
  font-size: 14px;
  color: #6C6C6F;
  padding: 10px 20px 40px;
  border-top: 1px solid #f2f2f2;
}

.rb-wrap {
  background-color: #fff;
  .rb-title {
    padding: 15px 30px;
    font-size: 22px;
    border-bottom: 1px solid #f2f2f2;
  }

  .rb-info {
    display: flex;
    padding: 10px 30px;  
  }

  .rb-img {
    width: 60px;
    height: 60px;
    background-color: #dfdfdf;
    border-radius: 50%;

      overflow: hidden;
  position: relative;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
  }
  }

  .rb-name {
    width: 160px;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #6c6c6c;
  }

  .btn-ad {
    width: 80px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    color: #FF450B;
    border: 1px solid #FF450B;
    border-radius: 3px;
    
  }

  .rb-msg {
    font-size: 14px;
    color: #6c6c6c;
    width: 280px;
    padding: 20px 30px 40px;
  }
}

</style>
