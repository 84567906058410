<template>
  <div class="testForm">
    <div class="form-title">共{{ formData.length }}道题</div>
    <div class="qusetion-box" v-for="(item, index) in formData" :key="index">
      <template v-if="Math.ceil(item.questionId / 10) == pageNo">
        <div class="qusetion-title">
          {{ item.questionId + ". " + item.question }}
        </div>
        <div class="options-box">
          <div class="type-check" v-if="item.questionType > 1">
            <el-radio-group v-model="item.answer">
              <!-- <el-radio v-for="(item,index) in item.options" :key="index" :label="item.val">{{item.label}}</el-radio> -->
              <el-radio class="opt-radio" label="A">{{
                item.choiceA
              }}</el-radio>
              <el-radio class="opt-radio" label="B">{{
                item.choiceB
              }}</el-radio>
            </el-radio-group>
          </div>
          <div class="type-option" v-if="item.questionType == 1">
            <div
              @click="optCheck(item, 'A')"
              class="opt-item"
              :class="item.answer == 'A' ? 'checked' : ''"
            >
              {{ item.choiceA }}
            </div>
            <div
              @click="optCheck(item, 'B')"
              class="opt-item"
              :class="item.answer == 'B' ? 'checked' : ''"
            >
              {{ item.choiceB }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <br />
    <br />
    <div class="form-bot">
      <el-pagination
        layout="prev, pager, next"
        :total="formData.length"
        :current-page="pageNo"
        @current-change="pageChange"
      >
      </el-pagination>
      <div class="btn-box">
        <div v-if="pageNo > 1" @click="prePage()" class="btn-submit">
          上一页
        </div>
        <div v-if="pageNo < 10" @click="nextPage()" class="btn-submit">
          下一页
        </div>
        <div v-if="pageNo == 10" @click="submit()" class="btn-submit">提交</div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "testForm",
  props: {
    formData: Array,
  },

  data() {
    return {
      showData: [],
      activeIndex: "1",
      answerList: [],
      pageNo: 1,
    };
  },
  mounted() {
    this.showDataInit();
  },
  methods: {
    showDataInit() {
      this.formData.forEach((item) => {
        item.answer = "";
        return item;
      });
    },
    pageChange(no) {
      this.pushAnswer();
      this.pageNo = no;
    },
    nextPage() {
      this.pageChange(this.pageNo + 1);
    },
    prePage() {
      this.pageChange(this.pageNo - 1);
    },
    optCheck(q, a) {
      q.answer = a;
    },
    pushAnswer() {
      let this_ = this;
      this.formData.map((item) => {
        if (item.answer) {
          this_.answerList[item.questionId - 1] = item.answer;
        }
      });
    },
    submit() {
      let this_ = this;
      this.pushAnswer();
      let answerListP = this.answerList;
      answerListP = answerListP.filter((d) => d);

      if (answerListP.length == this.formData.length) {
        answerListP = answerListP.map((item, index) => {
          return {
            questionId: `${index + 1}`,
            userId: JSON.parse(localStorage.getItem("userInfo")).id,
            score: item,
          };
        });
        this_.$http
          .post(this_.$apis.submitTestAnswer, answerListP)
          .then((res) => {
            this_.answerList = [];
            this_.$router.push({ path: "/character/report" });
          });
      } else {
        let noAnswer = "";
        this_.formData.map((item) => {
          if (!item.answer) {
            noAnswer = noAnswer + item.questionId + ", ";
          }
        });
        this.$message.error(`未答题目：${noAnswer}请填回答全部问题！`);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.testForm {
  padding: 50px 75px;
}

.form-title {
  color: #939393;
  font-size: 14px;
  margin-bottom: 10px;
}

.qusetion-title {
  font-size: 14px;
  margin-bottom: 7px;
}

.options-box {
  margin-bottom: 41px;
}
.opt-radio {
  margin-right: 48px;
}
.type-check {
}
.type-option {
  display: flex;
  .opt-item {
    min-width: 118px;
    text-align: center;
    line-height: 34px;
    border: 1px solid #dce0e7;
    color: #101010;
    margin-right: 20px;
    font-size: 14px;
    padding: 0 15px;
    border-radius: 3px;
  }

  .opt-item.checked {
    border: 1px solid #ff450b;
    color: #fff;
    background-color: #ff450b;
  }
}

.form-bot {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btn-box {
    display: flex;
    justify-content: space-between;
  }
  .btn-submit {
    margin-left: 20px;
    font-size: 14px;
    background-color: #ff450b;
    width: 80px;
    line-height: 35px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
  }
}
</style>
