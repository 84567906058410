<template>
  <div class="auth-center">
    <hd></hd>
    <div class="main-screen">
      <div class="main-screen">
        <div class="top-wrap">
          <div class="top-title">添加角色</div>
          <div class="top-btn-wrap">
            <div class="btn btn-sub">确认</div>
            <div class="btn btn-cancel">取消</div>
          </div>
        </div>
        <div class="main-box">
          <div class="main-title">角色信息</div>
          <br />
          <div class="info-wrap">
            <div class="info1 info">
              <div class="info-title">角色名称</div>
              <el-input v-model="input" placeholder="请输入角色名称"></el-input>
            </div>
            <div class="info2 info">
              <div class="info-title">角色名称</div>
              <el-input v-model="input" placeholder="请输入角色描述"></el-input>
            </div>
          </div>
        </div>
        <div class="main-box">
          <div class="main-title">功能权限</div>
          <br />
          <div class="scope-box">
            <div class="scope-title">数据可见范围:</div>
            <el-radio-group v-model="scope">
              <el-radio :label="1">公司数据</el-radio>
              <el-radio :label="2">部门数据</el-radio>
              <el-radio :label="3">个人数据</el-radio>
            </el-radio-group>
          </div>
          <div class="auth-box">
            <div class="auth-row">
              <div class="auth-item">可见页面</div>
              <div class="auth-item">功能权限</div>
            </div>
            <div class="auth-row" v-for="(row, index) in authList" :key="index">
              <el-checkbox
                class="auth-item"
                v-for="(item, index) in row"
                :key="index"
                v-model="item.val"
                >{{ item.label }}</el-checkbox
              >
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
    <ft></ft>
  </div>
</template>
<script>
import hd from "../common/header/hd";
import ft from "../common/footer/ft";
export default {
  name: "authAdd",
  components: {
    hd,
    ft,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      scope: 1,
      authList: [
        [
          {
            label: "页面名称1",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
        ],
        [
          {
            label: "页面名称2",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
        ],
        [
          {
            label: "页面名称3",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
        ],
      ],
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.auth-center {
  background-color: #f2f2f2;
}

.main-screen {
  width: 1200px;
  margin: 50px auto 100px;
}

.top-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .top-title {
    color: rgba(16, 16, 16, 100);
    font-size: 28px;
    font-weight: 700;
    position: relative;
    padding-left: 20px;
  }
  .top-title::after {
    position: absolute;
    display: block;
    content: "";
    width: 8px;
    border-radius: 4px;
    height: 40px;
    background-color: #ff450b;

    top: 0;
    left: 0;
  }
  .top-btn-wrap {
    display: flex;

    div {
      width: 100px;
      height: 40px;
      line-height: 40px;
      border-radius: 2px;
      background-color: rgba(255, 69, 11, 100);
      text-align: center;
      color: #fff;
      margin-left: 20px;
    }
    .btn-cancel {
      color: rgba(255, 69, 11, 100);
      text-align: center;
      background-color: #fff;
      border: 1px solid rgba(255, 69, 11, 100);
    }
  }
}
.main-title {
  font-weight: 700;
  color: rgba(16, 16, 16, 100);
  font-size: 20px;
}

.main-box {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 8px;

  .info-wrap {
    display: flex;
    justify-content: space-between;
    .info1 {
      width: 35%;
    }
    .info2 {
      width: 55%;
    }
  }
  .info-title {
    color: rgba(16, 16, 16, 100);
    font-size: 15px;
    margin-bottom: 15px;
    font-weight: 700;
  }
}

.scope-box {
  background-color: #f2f2f7;
  display: flex;
  padding: 30px;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  .scope-title {
    color: rgba(34, 34, 34, 100);
    font-size: 14px;
  }
}
.auth-box {
  .auth-row {
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    padding: 30px 20px;

    .auth-item {
      width: 150px;
    }
    .auth-item:first-child {
      width: 200px;
    }
  }
  .auth-row:first-child {
    .auth-item {
      color: rgba(34, 34, 34, 100);
      font-size: 15px;
      font-weight: 700;
    }
  }
}
</style>
