<template>
  <div class="h-box">
    <div class="header">
      <div class="left-box">
        <div class="logo-box">
          <img src="../../../assets/img/logo.png" alt="" />
        </div>
        <div class="menu-wrap">
          <div class="menu-item home">首页</div>
          <div class="menu-item">
            智能填报
            <i class="if idown"></i>
            <div class="item-more l">
              <im></im>
            </div>
          </div>
          <div class="menu-item">
            高考冲刺<i class="if idown"></i>
            <div class="item-more">
              <div class="mim-item">单词突击营</div>
              <div class="mim-item">押题卷</div>
              <div class="mim-item">考点梳理视频课程</div>
            </div>
          </div>
          <div class="menu-item">
            升学通道<i class="if idown"></i>
            <div class="item-more">
              <div class="mim-item">综合评价系统</div>
              <div class="mim-item">港澳台系统</div>
              <div class="mim-item">强基计划系统</div>
              <div class="mim-item no">尽请期待（高考日语、留学）</div>
            </div>
          </div>
          <div class="menu-item">校区介绍</div>
          <div class="menu-item">公司介绍</div>
          <div class="menu-item">联系我们</div>
        </div>
      </div>
      <div class="right-box">
        <div
          class="btn-loc"
          @click="locSel()"
          :class="{ check: !(loc == '地点') }"
        >
          <i class="if ilocation"></i>【{{ loc }}】
        </div>
        <div v-if="userInfo.id" class="user-name">
          {{ userInfo.fullname }}
        </div>
        <div v-else class="btn-signin" @click="login()">登录</div>
      </div>
      <div class="loc-sel-wrap" v-if="locSelS">
        <div class="loc-sel-box">
          <div class="loc-title">请选择你所在的高考省份</div>
          <div class="loc-item-box">
            <div
              class="loc-item"
              @click="locCheck(item.value)"
              v-for="item in locList"
              :key="item.value"
              :class="{ check: locL == item.value }"
            >
              {{ item.value }}
            </div>
          </div>
          <div class="btn-loc-sel" @click="locSel()">选好了</div>
        </div>
      </div>
    </div>
    <login v-if="loginPop"></login>
  </div>
</template>
<script>
import im from "./itemMore";
import login from "../../common/popup/login";

export default {
  name: "homehd",
  props: {
    msg: String,
    locList: Array,
  },

  components: {
    im,
    login,
  },
  data() {
    return {
      loginPop: false,
      userInfo: {
        authCode: "",
        fullname: "",
        hash: "",
        id: null,
        password: null,
        phone: "",
        region: "地点",
        time: "",
        username: null,
      },
      activeIndex: "1",
      locSelS: false,
      loc: "地点",
      locL: "地点",
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      if (localStorage.getItem("userInfo")) {
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      }
      console.log(this.userInfo);
    },
    login() {
      this.loginPop = true;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    locSel() {
      this.locSelS = !this.locSelS;
      this.loc = this.locL;
    },
    locCheck(loc) {
      this.locL = loc;
    },
  },
  watch: {
    userInfo() {
      this.loc = this.userInfo.region;
      this.locL = this.userInfo.region;
    },
  },
};
</script>

<style lang="less">
.header {
  color: #646464;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.h-box {
  background-color: #fff;
}
.header {
  width: 1200px;
  margin: 0 auto;
  height: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-box,
.right-box {
  display: flex;
  align-items: center;
}

.logo-box {
  width: 220px;
  img {
    width: 80%;
  }
}

.menu-wrap {
  display: flex;
  align-items: center;
}

.menu-item {
  margin-right: 30px;
  font-size: 15px;
  font-weight: 700;
  padding: 10px 0;
  position: relative;
  cursor: pointer;

  i {
    font-size: 16px;
    padding-left: 5px;
  }
}

.item-more {
  display: none;
}

.menu-item:hover {
  .item-more {
    display: inline-block;
    position: absolute;
    left: -5px;
    top: 44px;
    border: 1px solid #dce0e7;
    font-weight: 400;
    padding: 5px 0;
    width: 225px;
    z-index: 888;
    background-color: #fff;
  }

  .idown::before {
    content: "\e65c";
  }
  .mim-item {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
  }

  .mim-item.no {
    color: #8f8e94;
  }
  .mim-item:hover {
    color: #ff450b;
    background-color: #f8f2ed;
  }
}

.menu-item.home {
  color: #ff450b;
  border-bottom: 2px solid #ff450b;
}

.btn-loc,
.btn-signin,
.btn-signup {
  margin-left: 20px;
  font-size: 16px;
}

.btn-loc.check {
  color: #ff450b;
}

.btn-signin {
  background-color: #ff450b;
  color: #fff;
  width: 80px;
  line-height: 30px;
  text-align: center;
  border-radius: 15px;
}
.menu-item:hover .item-more.l {
  width: 520px;
  padding: 0;
}
.more-l {
  display: flex;
  .iml-l {
    width: 112px;
    padding: 5px 15px;
    border-right: 2px solid #f4f5f4;
    div {
      padding: 15px 0;
      cursor: pointer;
    }

    div.checked {
      color: #101010;
      font-weight: 700;
      position: relative;
    }
    div.checked::after {
      display: block;
      content: "";
      position: absolute;
      top: 15px;
      left: 95px;
      width: 2px;
      height: 25px;
      background-color: #ff450b;
    }
  }

  .iml-r {
    width: 407px;
    padding: 30px 25px;
  }

  .iml-c1 {
    display: flex;
    .c1-i {
      width: 152px;
      line-height: 35px;
      background-color: #f8f2ed;
      font-size: 14px;
      text-align: center;
      color: #ff450b;
      margin-right: 30px;
      position: relative;
      cursor: pointer;
      i {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
  }

  .iml-c2 {
  }

  .c2-i,
  .c3-i,
  .c4-i {
    display: flex;
    padding: 10px 0;
    div {
      cursor: pointer;
      padding-right: 15px;
      margin-right: 15px;
      border-right: 1px solid #bbb;
      font-size: 14px;
    }

    div:hover {
      color: #ff450b;
    }
  }
}

.loc-sel-wrap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;

  .loc-sel-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 1000px;
    min-height: 550px;
  }

  .loc-title {
    color: #101010;
    font-size: 24px;
    text-align: center;
    padding: 40px 0 30px;
    font-weight: 700;
  }

  .loc-item-box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 35px 30px;
  }

  .loc-item {
    padding: 0 8px;
    min-width: 120px;
    line-height: 55px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #bbb;
    border-radius: 5px;
    margin: 0 5px 25px;
  }

  .loc-item.check {
    color: #fff;
    background-color: #ff450b;
    border: 1px solid #ff450b;
  }

  .btn-loc-sel {
    width: 177px;
    text-align: center;
    line-height: 55px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    background-color: #ff450b;
    border-radius: 5px;
    margin: 0 auto 55px;
  }
}
</style>
