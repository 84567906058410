<template>
  <div class="login com">
    <div class="main-page">
      <div class="main-screen">
        <div class="login-wrap">
          <div class="logo-box">
            <div class="logo">LOGO</div>
          </div>
          <div class="info-box">
            <el-input class="t" v-model="userName" placeholder="请输入用户名">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <el-input class="b" v-model="pwd" placeholder="请输入密码">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <el-checkbox class="rem-checkbox" v-model="remember">记住密码</el-checkbox>
          <div class="btn-login">登 录</div>
          <div class="bot-box">
            <div class="btn-signup">注册账号</div>
            <div class="btn-pwd">忘记密码</div>
          </div>
        </div>
      </div>
    </div>
    <ft></ft>
  </div>
</template>
<script>
import ft from "../common/footer/ft";
export default {
  name: "login",
  components: {
    ft,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      remember: true,
      userName: "",
      pwd: ""
    }
  },
};
</script>
<style lang="less">
    .info-box {
 
        .t {
            input {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }
        }
        .b {
            input {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
            }
        }
    }
</style>
<style lang="less" scoped>
.main-page {
    width: 100%;
    min-height: 800px;
    height: calc(100vh - 50px);
    background-color: #cfcfcf;
    margin-bottom: 50px;
}

.main-screen {
    position: relative;
}

.login-wrap {
    background-color: #fff;
    position: absolute;
    top: 20vh;
    right: 0;
    height: 450px;
    width: 500px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 100);

    .logo-box {
        padding: 75px 0 30px;
        text-align: center;
        .logo {
            display: inline-block;
            width: 75px;
            height: 75px;
            background-color: #FF450B;
            border-radius: 50%;
            line-height: 75PX;
            color: #fff;
            font-weight: 700;
        }
    }
    .info-box {
        width: 340px;
        margin: 0 auto 20px;
    }
    .rem-checkbox{
      width: 340px;
        margin: 0 85px 20px;
    }

    .btn-login {
      background-color: #FF450B;
      color: #fff;
      line-height: 45px;
      text-align: center;
      width: 340px;
      border-radius: 3px;
      margin: 0 auto 20px;
    }

    .bot-box {
      display: flex;
      justify-content: space-between;
      width: 340px;
      position: relative;
      margin: 0 auto;
      div {
        font-size: 12px;
        color: #FF450B;
      }
      ::after {
        position: absolute;
        display: inline-block;
        content: "";
        width: 1px;
        height: 20px;
        background-color: #bbb;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }
}
</style>