<template>
  <div class="h-box">
    <div class="header">
      <div class="left-box">
        <div class="logo-box">
          <img src="../../../assets/img/logo_o.png" alt="" />
        </div>
        <div class="menu-wrap">
          <div class="menu-item">
            智能填报<i class="if idown"></i>
            <div class="item-more l">
              <im></im>
            </div>
          </div>
          <div class="menu-item">联系我们</div>
          <div class="menu-item"><i class="if igift"></i>关注领福利</div>
        </div>
      </div>
      <div class="search-box">
        <input type="text" placeholder="搜索" name="" id="" />
        <i class="if isearch"></i>
      </div>
      <div v-if="$store.getters.getUserInfo.fullname" class="right-box">
        <div class="btn-loc">
          <i class="if ilocation"></i>【{{
            $store.getters.getUserInfo.region
          }}】
        </div>
        <div class="btn-ling"><i class="if iling"></i></div>
        <div class="btn-username">
          {{ $store.getters.getUserInfo.fullname }}
        </div>
        <div class="btn-user-img">
          <img src="../../../assets/img/ws.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import im from "./itemMore";
export default {
  name: "hd",
  props: {
    msg: String,
  },
  components: {
    im,
  },

  data() {
    return {
      activeIndex: "1",
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.h-box {
  background-color: #ff450b;
}
.header {
  width: 1200px;
  margin: 0 auto;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.left-box,
.right-box {
  display: flex;
  align-items: center;
  i {
    font-size: 20px;
  }
}

.right-box {
}

.logo-box {
  width: 220px;
  img {
    width: 80%;
  }
}

.menu-wrap {
  display: flex;
  align-items: center;
}

.menu-item {
  margin-right: 30px;
  font-size: 16px;
  padding: 10px 0;

  .idown {
    margin-left: 10px;
  }
  .igift {
    margin-right: 5px;
  }
}

.btn-loc,
.btn-signin,
.btn-signup {
  margin-left: 30px;
}

.search-box {
  background-color: #fff;
  height: 45px;
  border-radius: 23px;
  width: 250px;
  position: relative;
  input {
    border: none;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    height: 30px;
    outline: none;
  }

  i {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: #ff450b;
  }
}

.btn-loc {
  i {
    margin-right: 7px;
  }
}

.btn-ling {
  margin-left: 30px;
}

.btn-username {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #fff;
}

.btn-user-img {
  margin-left: 15px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
  }
}

.item-more {
  display: none;
}

.menu-item:hover {
  position: relative;
  .item-more {
    display: inline-block;
    position: absolute;
    left: -5px;
    top: 44px;
    border: 1px solid #dce0e7;
    font-weight: 400;
    padding: 5px 0;
    width: 225px;
    z-index: 888;
    background-color: #fff;
  }

  .idown::before {
    content: "\e65c";
  }
  .mim-item {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
  }

  .mim-item.no {
    color: #8f8e94;
  }
  .mim-item:hover {
    color: #ff450b;
    background-color: #f8f2ed;
  }
}

.menu-item:hover .item-more.l {
  width: 520px;
  padding: 0;
}
.more-l {
  display: flex;
  .iml-l {
    width: 112px;
    padding: 5px 15px;
    border-right: 2px solid #f4f5f4;
    div {
      padding: 15px 0;
      cursor: pointer;
    }

    div.checked {
      color: #101010;
      font-weight: 700;
      position: relative;
    }
    div.checked::after {
      display: block;
      content: "";
      position: absolute;
      top: 15px;
      left: 95px;
      width: 2px;
      height: 25px;
      background-color: #ff450b;
    }
  }

  .iml-r {
    width: 407px;
    padding: 30px 25px;
  }

  .iml-c1 {
    display: flex;
    .c1-i {
      width: 152px;
      line-height: 35px;
      background-color: #f8f2ed;
      font-size: 14px;
      text-align: center;
      color: #ff450b;
      margin-right: 30px;
      position: relative;
      cursor: pointer;
      i {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
  }

  .iml-c2 {
  }

  .c2-i,
  .c3-i,
  .c4-i {
    display: flex;
    padding: 10px 0;
    div {
      cursor: pointer;
      padding-right: 15px;
      margin-right: 15px;
      border-right: 1px solid #bbb;
      font-size: 14px;
    }

    div:hover {
      color: #ff450b;
    }
  }
}
</style>
