<template>
  <div class="auth-center admin-auth">
    <hd></hd>
    <div class="main-screen">
      <div class="top-wrap">
        <div class="top-title">角色权限</div>
        <div class="top-btn-wrap">
          <div class="btn add-auth">添加角色</div>
        </div>
      </div>
      <div class="main-wrap">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="name" label="角色名称" width="200">
          </el-table-column>
          <el-table-column prop="msg" label="角色描述" width="600">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div class="fbtn-wrap">
                <div @click="authInfoShow(scope.$index, scope.row)">详情</div>
                <div @click="ttt(scope.$index, scope.row)">编辑</div>
                <div class="del" @click="ttt(scope.$index, scope.row)">
                  删除
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <br />
        <el-pagination
          layout="prev, pager, next"
          :total="tableData.length"
          :current-page="pageNo"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      class="auth-dialog"
      title="角色详情"
      :visible.sync="authInfo"
      width="1200px"
    >
      <div class="sw-btn-box">
        <div class="sw-btn" :class="swVal == 1 && 'check'" @click="swClick(1)">
          角色权限
        </div>
        <div class="sw-btn" :class="swVal == 2 && 'check'" @click="swClick(2)">
          操作日志
        </div>
      </div>
      <br />

      <div class="main-box">
        <div v-if="swVal == 1" class="sw-auth-box">
          <div class="main-title">角色信息</div>
          <br />
          <div class="info-wrap">
            <div class="info1 info">
              <div class="info-title">角色名称</div>
              <div class="info-val">角色名称1</div>
            </div>
            <div class="info2 info">
              <div class="info-title">角色描述</div>
              <div class="info-val">角色描述2</div>
            </div>
          </div>
          <br />
          <div class="main-title">功能权限</div>
          <br />
          <div class="scope-box">
            <div class="scope-title">数据可见范围:</div>

            <div v-if="scope == 1">公司数据</div>
            <div v-if="scope == 2">部门数据</div>
            <div v-if="scope == 3">个人数据</div>
          </div>
          <div class="auth-box">
            <div class="auth-row">
              <div class="auth-item">可见页面</div>
              <div class="auth-item">功能权限</div>
            </div>
            <div class="auth-row" v-for="(row, index) in authList" :key="index">
              <div class="auth-item" v-for="(item, index) in row" :key="index">
                {{ item.label }}
              </div>
            </div>
          </div>
          <br />
        </div>
        <div v-else class="sw-log-box">
          <div class="main-title">操作日志</div>
          <div class="log-title-msg">
            此处只记录最近30天日志，如果想查看详细日志，请到<span>日志中心</span>查看
          </div>
          <br />
          <el-timeline>
            <el-timeline-item
              v-for="(date, index) in timeline"
              :key="index"
              :timestamp="date.date"
              placement="top"
            >
              <div
                v-for="(item, index) in date.items"
                :key="index"
                class="tl-item"
              >
                <div class="tli-msg">{{ item.msg }}</div>
                <div class="tli-time">{{ item.time }}</div>
              </div>
            </el-timeline-item>

            <el-timeline-item
              timestamp="无更多数据"
              placement="top"
            ></el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </el-dialog>
    <ft></ft>
  </div>
</template>
<script>
import hd from "../common/header/hd";
import ft from "../common/footer/ft";
export default {
  name: "adminAuth",
  components: {
    hd,
    ft,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      authInfo: false,
      pageNo: 1,
      swVal: 1,
      tableData: [
        {
          name: "王小虎",
          msg: "上海市普陀区金沙江路 1518 弄",
        },
        {
          name: "王小虎",
          msg: "上海市普陀区金沙江路 1517 弄",
        },
        {
          name: "王小虎",
          msg: "上海市普陀区金沙江路 1519 弄",
        },
        {
          name: "王小虎",
          msg: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      scope: 1,
      authList: [
        [
          {
            label: "页面名称1",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
        ],
        [
          {
            label: "页面名称2",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
        ],
        [
          {
            label: "页面名称3",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
          {
            label: "功能名称",
            val: false,
          },
        ],
      ],
      timeline: [
        {
          date: "2021/02/03",
          items: [
            {
              img: "111",
              time: "13:09",
              msg: `用户昵称 将 角色描述 "这是一段旧的描述" 变更为  "这是一段新的描述"`,
            },

            {
              img: "111",
              time: "13:09",
              msg: `用户昵称 将 角色名称 "超级管理员" 变更为  "系统管理员"`,
            },

            {
              img: "111",
              time: "13:09",
              msg: `用户昵称 将用户 "用户名称" 设置为"超级管理员"`,
            },
          ],
        },
        {
          date: "2021/01/14",
          items: [
            {
              img: "111",
              time: "13:09",
              msg: `用户昵称 将用户 "用户名称" 移除 "超级管理员"`,
            },

            {
              img: "111",
              time: "13:09",
              msg: `用户昵称  变更了 "数据可见范围"`,
            },
          ],
        },
      ],
    };
  },
  methods: {
    ttt(a, b) {
      console.log(a, b);
    },
    pageChange() {},
    authInfoShow() {
      this.authInfo = true;
    },
    swClick(num) {
      this.swVal = num;
    },
  },
};
</script>
<style>
.admin-auth .el-pagination button:disabled,
.admin-auth .el-pager li {
  background-color: transparent;
}
.admin-auth .el-dialog__header {
  background-color: #efefef;
  padding: 15px 35px;
}
.admin-auth .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

.admin-auth .el-dialog__title {
  color: rgba(16, 16, 16, 100);
  font-size: 15px;
}
</style>

<style lang="less" scoped>
.auth-center {
  background-color: #f2f2f2;
}

.main-screen {
  width: 1200px;
  margin: 50px auto 100px;
}

.top-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .top-title {
    color: rgba(16, 16, 16, 100);
    font-size: 28px;
    font-weight: 700;
    position: relative;
    padding-left: 20px;
  }
  .top-title::after {
    position: absolute;
    display: block;
    content: "";
    width: 8px;
    border-radius: 4px;
    height: 40px;
    background-color: #ff450b;

    top: 0;
    left: 0;
  }
  .top-btn-wrap {
    div {
      width: 133px;
      height: 48px;
      line-height: 48px;
      border-radius: 2px;
      background-color: rgba(255, 69, 11, 100);
      text-align: center;
      color: #fff;
    }
  }
}

.fbtn-wrap {
  display: flex;
  justify-content: flex-start;
  div {
    margin-right: 20px;
    color: #ffa005;
    cursor: pointer;
  }
  .del {
    color: #ff450b;
  }
}

.auth-dialog {
  .main-box {
    padding: 0 20px;
    .main-title {
      font-weight: 700;
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
    }
  }
  .sw-btn-box {
    display: flex;
    padding-left: 20px;
    .sw-btn {
      width: 120px;
      text-align: center;
      line-height: 40px;
      background-color: #f0f2f5;
      color: rgba(48, 49, 51, 100);
      font-size: 15px;
      cursor: pointer;
    }
    .sw-btn.check {
      background-color: #ff450b;
      color: #fff;
    }
  }
  .sw-auth-box {
    .info-wrap {
      display: flex;
      justify-content: space-between;
      .info1 {
        width: 35%;
      }
      .info2 {
        width: 55%;
      }
    }
    .info-title {
      color: rgba(16, 16, 16, 100);
      font-size: 15px;
      margin-bottom: 15px;
      font-weight: 700;
    }
    .scope-box {
      background-color: #f2f2f7;
      display: flex;
      padding: 30px;
      justify-content: space-between;
      align-items: center;
      border-radius: 2px;
      .scope-title {
        color: rgba(34, 34, 34, 100);
        font-size: 14px;
      }
    }
    .auth-box {
      .auth-row {
        border-bottom: 1px solid #f2f2f2;
        display: flex;
        padding: 30px 20px;

        .auth-item {
          width: 150px;
        }
        .auth-item:first-child {
          width: 200px;
        }
      }
      .auth-row:first-child {
        .auth-item {
          color: rgba(34, 34, 34, 100);
          font-size: 15px;
          font-weight: 700;
        }
      }
    }
  }
  .sw-log-box {
    .log-title-msg {
      color: rgba(142, 142, 147, 100);
      font-size: 12px;
      padding-top: 15px;
      span {
        color: #ff450b;
      }
    }
    .tl-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f2f2f2;
      padding: 15px 30px;
      margin-bottom: 10px;
    }

    .tli-msg {
      color: rgba(16, 16, 16, 100);
      font-size: 14px;
    }

    .tli-time {
      color: rgba(174, 174, 178, 100);
      font-size: 14px;
    }
  }
}
</style>
