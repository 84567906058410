import Vue from "vue"
import router from './router/index'
var app = new Vue();
import http from 'axios';
import { Toast } from 'vant';
import VueCookies from 'vue-cookies';
Vue.use(Toast);
var baseUrl = '/api'
const errorHandler = (error) => {
    if (error.response.code == 401 || error.response.data.code == 401) {
        localStorage.setItem("ACCESS_TOKEN", "");
        localStorage.setItem('ischangePWD', '');
        localStorage.setItem('ischangePDF', '');
        // if(router.currentRoute.path.startsWith("/back")){
        //     router.replace("/back/login")
        // }else{
        //     router.replace("/home")
        // }
        router.replace("/back/login");
        Vue.prototype.$message({ message: "用户身份已失效,请重新登录", type: "error"});
    }else{
        Vue.prototype.$message({ message: "请求失败", type: "error"});
    }
    count--;
    if (count == 0) {
        // app.$bus.emit('showLoading', false);
    }
}
http.interceptors.request.use(config => {
    config.timeout = 90000;
    const token = localStorage.getItem('ACCESS_TOKEN')
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        // config.headers['Access-Token'] = token
        config.headers['token'] = token
    }
    return config;
}, errorHandler)
http.interceptors.response.use((response) => {
    count--;
    if (count == 0) {
        // app.$bus.emit('showLoading', false);
    }
    if (response.data.code && response.data.code === 401) {
        if(router.currentRoute.path.startsWith("/back")){
            router.replace("/back/login")
        }else{
            router.replace("/home")
        }
        localStorage.removeItem('ACCESS_TOKEN');
        VueCookies.remove("ACCESS_TOKEN");
        Vue.prototype.$message({ message: "用户身份已失效,请重新登录", type: "error"});

    } else if (response.data.code && response.data.code === 403) {
        if(router.currentRoute.path.startsWith("/back")){
            router.replace("/back/login")
        }else{
            router.replace("/home")
        }
        Vue.prototype.$message({ message: "当前用户没有权限", type: "error"});
    }
    if(response.headers.token && response.headers.token !=""){
        localStorage.setItem("ACCESS_TOKEN", response.headers.token);
        VueCookies.set("ACCESS_TOKEN",response.headers.token,"1d");
    }
    return response.data
}, errorHandler)
let count = 0;
function get(url, params) {
    count++;
    // app.$bus.emit('showLoading', true);
    if (!params) {
        params = {};
    } else { }
    return http.get(baseUrl + url, { params: params })

}
function post(url, params, param) {
    count++;
    // app.$bus.emit('showLoading', true);
    return http.post(baseUrl + url, params, { params: param })
}
function picPost(reqUrl, params) {
    count++;
    var form = new FormData();
    // 文件对象
    form.append("file", params);
    const url = baseUrl + reqUrl
    // app.$bus.emit('showLoading', true);
    return http.post(
        url,
        form
    )
}
function put(url, params) {
    count++;
    // app.$bus.emit('showLoading', true);
    return http.put(baseUrl + url, params)
}
function postForResult(url, params, param) {
    count++;
    return http.post(baseUrl + url, params, { params: param })
}

export default {
    get,
    post,
    put,
    picPost,
    postForResult
};