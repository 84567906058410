<template>
  <div class="colleges">
    <hd></hd>
    <div class="main-screen">
      <div class="left-wrap">
        <div class="stu-wrap">
          <div class="stu-i" :class="{'classCheck' : stuId == item.id}" v-for="(item,index) in classLsit[0]" :key="index" @click="classSel('stuId',item.id)">{{item.label}}</div>
        </div>
        <div class="lv-wrap">
          <div class="lv-i" :class="{'classCheck' : lvId == 0}" @click="classSel('lvId',0)">不限</div>
          <div class="lv-i" :class="{'classCheck' : lvId == item.id}" v-for="(item,index) in classLsit[1]" :key="index" @click="classSel('lvId',item.id)">{{item.label}}</div>
        </div>
        <div class="loc-wrap">
          <div class="loc-i" :class="{'classCheck' : locId == 0}" @click="classSel('locId',0)">不限</div>
          <div class="loc-i" :class="{'classCheck' : locId == item.id}" v-for="(item,index) in classLsit[2]" :key="index" @click="classSel('locId',item.id)">{{item.label}}</div>
        </div>
        <div class="cla-wrap">
          <div class="cla-i" :class="{'classCheck' : claId == 0}" @click="classSel('claId',0)">不限</div>
          <div class="cla-i" :class="{'classCheck' : claId == item.id}" v-for="(item,index) in classLsit[3]" :key="index" @click="classSel('claId',item.id)">{{item.label}}</div>
        </div>
        <div class="otr-wrap">
          <div class="otr-i" :class="{'classCheck' : otrId == 0}" @click="classSel('otrId',0)">不限</div>
          <div class="otr-i" :class="{'classCheck' : otrId == item.id}" v-for="(item,index) in classLsit[4]" :key="index" @click="classSel('otrId',item.id)">{{item.label}}</div>
        </div>
        <div class="res-wrap">
          <div class="res-i" :class="{'classCheck' : resId == 0}" @click="classSel('resId',0)">不限</div>
          <div class="res-i" :class="{'classCheck' : resId == item.id}" v-for="(item,index) in classLsit[5]" :key="index" @click="classSel('resId',item.id)">{{item.label}}</div>
        </div>
      </div>
      <div class="right-wrap">
        <div class="data-pro">匹配到<span class="pro-no">2913</span>条数据</div>
        <div class="colleges-wrap">
          <div class="col-i" v-for=" (item,index) in collegeList" :key="index">
            <img class="col-img" src="../../assets/img/qh.png" alt="">
            <div class="col-cont">
              <div class="col-title">{{item.label+(index+1)}}</div>
              <div class="info-box">
                <div class="ci-item">综合排名：{{item.rank}}</div>
                <div class="ci-item">院校类型：{{item.class}}</div>
                <div class="ci-item">省内排名：{{item.sRank}}</div>
                <div class="ci-item">学院性质：{{item.res}}</div>
              </div>
              <div class="flag-box">
                <div class="cf-item" v-for="(item,index) in item.flag" :key="index">{{item}}</div>
              </div>
            </div>
            <div class="col-btn">
              <div class="btn-keep">收藏</div>
              <div class="btn-contrast">对比</div>
            </div>

          </div>
        </div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage3" :page-size="100" layout="prev, pager, next, jumper" :total="1000">
        </el-pagination>
      </div>
    </div>
    <ft></ft>
  </div>
</template>
<script>
import hd from "../common/header/hd";
import ft from "../common/footer/ft";
export default {
  name: "colleges",
  components: {
    hd,
    ft
  },
  props: {
    msg: String,
  },
  data() {
    return {
      currentPage3: 5,
      stuId: 11,
      lvId: 0,
      locId: 0,
      claId: 0,
      otrId: 0,
      resId: 0,
      classLsit: [
        [
          {
            label: "文化生",
            id: 11
          },
          {
            label: "艺术生",
            id: 12
          },
          {
            label: "高职单招",
            id: 13
          },
        ],
        [
          {
            label: "本科",
            id: 21
          },
          {
            label: "专科",
            id: 12
          },
        ],
        [
          {
            label: "安徽",
            id: 31
          },
          {
            label: "北京",
            id: 32
          },
          {
            label: "重庆",
            id: 33
          },
          {
            label: "辽宁",
            id: 34
          },
          {
            label: "宁夏",
            id: 35
          },
          {
            label: "山东",
            id: 36
          },
          {
            label: "内蒙古",
            id: 37
          },
          {
            label: "吉林",
            id: 38
          },
          {
            label: "黑龙江",
            id: 39
          },
          {
            label: "云南",
            id: 310
          },
          {
            label: "贵州",
            id: 311
          },
          {
            label: "四川",
            id: 312
          },
        ],


        [
          {
            label: "综合类",
            id: 41
          },
          {
            label: "理工类",
            id: 42
          },
          {
            label: "财经类",
            id: 43
          },
          {
            label: "农业类",
            id: 44
          },
          {
            label: "师范类",
            id: 45
          },
          {
            label: "体育类",
            id: 46
          },
          {
            label: "医药类",
            id: 47
          },
        ],

        [
          {
            label: "985",
            id: 51
          },
          {
            label: "211",
            id: 52
          },
          {
            label: "双一流",
            id: 53
          },
          {
            label: "国重点",
            id: 54
          },
          {
            label: "省重点",
            id: 55
          },
        ],
        [
          {
            label: "公办",
            id: 61
          },
          {
            label: "民办",
            id: 62
          },
          {
            label: "中外合作办学",
            id: 63
          },
          {
            label: "港澳台合作办学",
            id: 64
          },
        ],
      ],
      collegeList: [
        {
          label: "清华大学",
          rank: "1/2913",
          sRank: "1/112",
          class: "理工类",
          res: "公办",
          flag: ["985", "211", "双一流", "国重点", "艺术"]
        },
        {
          label: "清华大学",
          rank: "1/2913",
          sRank: "1/112",
          class: "理工类",
          res: "公办",
          flag: ["985", "211", "双一流", "国重点", "艺术"]
        },
        {
          label: "清华大学",
          rank: "1/2913",
          sRank: "1/112",
          class: "理工类",
          res: "公办",
          flag: ["985", "211", "双一流", "国重点", "艺术"]
        },
        {
          label: "清华大学",
          rank: "1/2913",
          sRank: "1/112",
          class: "理工类",
          res: "公办",
          flag: ["985", "211", "双一流", "国重点", "艺术"]
        },
        {
          label: "清华大学",
          rank: "1/2913",
          sRank: "1/112",
          class: "理工类",
          res: "公办",
          flag: ["985", "211", "双一流", "国重点", "艺术"]
        },
        {
          label: "清华大学",
          rank: "1/2913",
          sRank: "1/112",
          class: "理工类",
          res: "公办",
          flag: ["985", "211", "双一流", "国重点", "艺术"]
        },
        {
          label: "清华大学",
          rank: "1/2913",
          sRank: "1/112",
          class: "理工类",
          res: "公办",
          flag: ["985", "211", "双一流", "国重点", "艺术"]
        },
        {
          label: "清华大学",
          rank: "1/2913",
          sRank: "1/112",
          class: "理工类",
          res: "公办",
          flag: ["985", "211", "双一流", "国重点", "艺术"]
        },
        {
          label: "清华大学",
          rank: "1/2913",
          sRank: "1/112",
          class: "理工类",
          res: "公办",
          flag: ["985", "211", "双一流", "国重点", "艺术"]
        },
        {
          label: "清华大学",
          rank: "1/2913",
          sRank: "1/112",
          class: "理工类",
          res: "公办",
          flag: ["985", "211", "双一流", "国重点", "艺术"]
        },
      ]
    }
  },
  methods: {
    classSel(cla, id) {
      this[cla] = id;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    }
  }
};
</script>

<style lang="less" scoped>
.main-screen {
    width: 1200px;
    margin: 45px auto 120px;
    display: flex;
}

.left-wrap {
    width: 300px;
    padding: 15px 30px 30px;
    border: 1px solid #E6E6E6;
    margin-right: 30px;
}

.stu-wrap {
    display: flex;
    margin-bottom: 25px;
}

.stu-i {
    width: 33.333%;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #E6E6E6;
}

.stu-i.classCheck {
    color: #FF450B;
    border-color: #FF450B;
}



.lv-wrap,.loc-wrap,.cla-wrap,.otr-wrap,.res-wrap {
    display: flex;
    border-bottom: 1px solid #E6E6E6;
    flex-wrap: wrap;
    padding-bottom: 10px;
    margin-bottom: 25px;
}

.lv-i,.loc-i,.cla-i,.otr-i,.res-i {
    min-width: 24%;
    line-height: 31px;
    margin-bottom:5px;
    text-align: center;
    padding: 0 5px;
    border-radius: 3px;
    font-size: 16px;
    color: #B0B0B0;
}

.lv-i.classCheck,.loc-i.classCheck,.cla-i.classCheck,.otr-i.classCheck,.res-i.classCheck {
    color: #fff;
    background-color: #FF450B;
}

.right-wrap {
    width: 870px;
}
.colleges-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.col-i {
    width: 49%;
    display: flex;
    height: 140px;
    align-items: center;
}

.col-img {
    height: 75px;
    width: 75px;
}

.col-cont {
    width: 285px;
    padding-left: 10px;
}

.col-title {
    font-size: 20px;
    font-weight: 700;
}

.info-box {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0;
}

.ci-item {
    width: 50%;
    color: #B0B0B0;
    font-size: 14px;
}

.ci-item:nth-child(2n-1) {
  width: 55%;
}

.ci-item:nth-child(2n) {
  width: 45%;
}

.flag-box {
    display: flex;
    flex-wrap: wrap;
}

.cf-item {
    transform: scale(0.95);
    text-align: center;
    font-size: 12px;
    width: 40px;
    margin-right: 3px;
    border-radius: 2px;
    border: 1px solid #FF450B;
    color:#FF450B;
}

.cf-item:nth-child(2) {
    border: 1px solid #FFA005;
    color:#FFA005;
}


.cf-item:nth-child(3) {
    border: 1px solid #9DD950;
    color:#9DD950;
}


.cf-item:nth-child(4) {
    border: 1px solid #6350D9;
    color:#6350D9;
}


.cf-item:nth-child(5) {
    border: 1px solid #D950C7;
    color:#D950C7;
}

.btn-keep, .btn-contrast {
    width: 65px;
    text-align: center;
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid #B0B0B0;
    color:#B0B0B0;
    margin: 20px 0;
}

</style>
